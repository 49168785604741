<template>
    <div id="firstPage" v-title :title="appName">
        <!-- <DownLoad :ifphone="isMobile" :screenWidth="screenWidth"></DownLoad> -->
        <Title  :ifphone="isMobile" :screenWidth="screenWidth" :title="title" :text="text" :fontSize="!ifphone?'34px':'2.125rem'" ></Title>
        <ImageComparatorNew @getTitle="getTitle" @getText="getText" :per="per" :ifphone="isMobile" :screenWidth="screenWidth" :style="{backgroundColor:'#f6f7fc'}"></ImageComparatorNew>
        <Blank :height="isMobile?'5rem':'90px'" color="#f6f7fc"></Blank>
        <About  :ifphone="isMobile" :screenWidth="screenWidth"></About>
        <div class="miniprogram-container" v-if="!ifphone">
          <img class="miniprogram-qr-code-image" src="@/static/ico/gh_d69bc3248743_258.jpg" width="192px" height="192px">
          <div class="miniprogram-instant-text">微信扫码立即体验</div>
        </div>
    </div>
</template>
<script type="text/javascript">
import DownLoad from '@/components/downLoad/DownLoad'
// import ImageComparatorNew from '@/components/ImageComparatorNew'
import ImageComparatorNew from '@/components/ImageComparator-1.1'
import About from '@/components/about/about'
import Blank from '@/components/blank/blank'
import Title from '@/components/title/title'
import valueGetters from "../common/mixins/valueGetters.js";
export default {
  name: 'FirstPage',
  mixins:[valueGetters],
  components: {
    DownLoad,
    ImageComparatorNew,
    About,
    Blank,
    Title
  },
  mounted(){
    const that = this;
     window.addEventListener('resize',()=>{
       that.per= that.screenWidth/that.offsetWidth*100;
       that.screenWidth= document.body.clientWidth
     })
  },
  data(){
    return{
      per:100,
      screenWidth: document.body.clientWidth,
      offsetWidth: document.body.offsetWidth,
      text:'让朦胧的人像顾盼生辉',
      title:'消除模糊'
    }
  },
  methods:{
    getTitle(val){
      this.title = val;
    },
    getText(val){
      this.text = val;
    }
  },
  computed:{
    ifphone(){
      return this.screenWidth<900?true:false;
    },
    isMobile() {
        var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)!=null;
      return flag;
    }
  }
    
}
</script>
<style lang="scss" scoped>
  .miniprogram-container{
    position: fixed;
    right: 20px;
    bottom: calc(50vh - 192px / 2);
    height: 192px;
    width: 192px + 30px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    .miniprogram-qr-code-image{
      height: 192px;
      width: 192px;
      border-radius: 192px;
    }
    .miniprogram-instant-text{
      color:#333135;
      padding-top: 12px;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: 800;
      font-stretch: normal;
      letter-spacing: 0.0625rem;
    }
  }
</style>