<template>
    <div id="imageComparator" :style="{height:ifphone?(imgWidth)+'px':imgWidth+'px'}" @touchend="handleUp" @mouseup="handleUp">
        <div class="imageComparator-div1">
            <template v-if="!ifphone">
                <img v-if="pageSize!=0" :style="{height:ifphone?20+'px':''}"  @click="pre()" src="@/static/function/rightbutton.png"/>
            </template>
        </div>
        <div class="block" @mousemove="handleMove" @touchmove.stop="handleMove"  @mousedown="imageClick"  @touchstart.stop="imageClick" :style="{width:ifphone?(imgWidth)+'px':imgWidth+'px',height:ifphone?(imgWidth)+'px':imgWidth+'px'}">
            <el-carousel ref="carousel" indicator-position="outside" trigger="click" arrow="never"  :height="ifphone?(imgWidth)+'px':imgWidth+'px'" :autoplay="false">
            <el-carousel-item v-for="item,index in images" :key="index">
                <div class="block-div1" :style="{backgroundImage:'url('+item.after+')',backgroundRepeat: 'no-repeat',height:imgWidth+'px'}">
                </div>
                <div class="block-div2" :style="{marginLeft:imgWidth/2+x+'px',backgroundImage:'url('+item.before+')',height:imgWidth+'px',backgroundRepeat: 'no-repeat'}" >
                    <img class="block-img2" :style="{transform:'translateX('+(-imgWidth/2-x)+'px)'}" :src="item.before" alt="">
                </div>
                <div class="cmp-btn"   :style="{width:ifphone?'3rem':'48px',height:ifphone?'1.5rem':'24px',marginLeft:!ifphone?imgWidth/2-(24)+x+'px':imgWidth/2-18+x+'px'}" @mousedown="handleDown"  @touchstart.stop="handleDown">
                    <div class="cmp-btn-text" >&lt;</div>
					<div class="cmp-btn-text" >&gt;</div>    
                </div>
            </el-carousel-item>
            </el-carousel>
            <!-- <div class="block-text" >
                <div>{{images[pageSize].text}}</div>
            </div> -->
            <!-- 页码 -->
            <!-- <template v-if="(7*16*per/100)>52">
                <div class="block-pageSize" v-if="!ifphone" :style="{height:ifphone?100+'px':imgWidth/6+'px'}">
                <div class="changePage" @click="pageSize--" > |&lt;上一页</div>
                    <template v-if="pageSize>=3" >
                        <div @click="changeCarousel(pageSize-5)" :style="{border:'initial'}">
                            ---
                        </div>
                    </template>
                    <template v-if="images.length<=5">
                        <div @click="changeCarousel(index)" :style="{backgroundColor:index==pageSize?'#0098e9':''}" v-for="item,index in images" :key="index">
                            {{index}}
                        </div>
                    </template>
                    <template v-else  v-for="value,index in images.length" >
                        <div v-if="index<pageSize+3 && index>pageSize-3"  @click="changeCarousel(index)" :style="{backgroundColor:index==pageSize?'#0098e9':'',color:index==pageSize?'#fff':''}" :key="index" >
                            {{index+1}}
                        </div>
                    </template>
                
                    <template v-if=" pageSize+3<images.length" >
                        <div @click="changeCarousel(pageSize+3)" :style="{border:'initial'}">
                            ---
                        </div>
                    </template>
                    <div class="changePage" @click="pageSize++">下一页&gt;|</div>
                    <div class="block-pageSize-end">共{{images.length}}页</div>
                </div>
            </template> -->
        </div>
        <div class="imageComparator-div3">
            <template v-if="!ifphone">
                <img v-if="pageSize!=images.length-1" :style="{height:ifphone?20+'px':''}"  @click="next()" src="@/static/function/leftbutton.png"/>
            </template>
        </div>
    </div>
</template>
<script>
import Title from '@/components/title/title'
export default {
    components:{
        Title
    },
    props:{
        per:Number,
        ifphone:Boolean,
        screenWidth:Number,
    },
    data(){
        return{
             images:[
                {
                    before:require('@/static/function/photograph/photographBefore.png'),
                    after:require('@/static/function/photograph/photographAfter.png'),
                    text:'让朦胧的人像顾盼生辉',
                    title:'消除模糊'
                },
                {
                    before:require('@/static/function/whiteblackinColor/whiteblackinColorBefore.png'),
                    after:require('@/static/function/whiteblackinColor/whiteblackinColorAfter.png'),
                    text:'让黯然的时光璀璨夺目',
                    title:'还原色彩'
                },
                {
                    before:require('@/static/function/oldphotoReNew/oldphoto-before.png'),
                    after:require('@/static/function/oldphotoReNew/oldphoto-after.png'),
                    text:'让模糊的记忆跃然纸上',
                    title:'旧照翻新'
                },
                {
                    before:require('@/static/function/artificial/ArtificialBefore.png'),
                    after:require('@/static/function/artificial/ArtificialAfter.png'),
                    text:'修复师定制修复，专业高精度还原',
                    title:'人工精修'
                },
            ],
            flag:false,
            handleDownClientX:0,
            x:0,
            pageSize:0,
            imageMove:false,
            imagePostion:0
        }
    },
    mounted(){
        let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
        let element = document.querySelector('.thatTitle')
        this.observer = new MutationObserver((mutationList) => {
        for (let mutation of mutationList) {
            console.log(mutation)
        }
        let width = getComputedStyle(element).getPropertyValue('width')
        let height = getComputedStyle(element).getPropertyValue('height')
        console.log(width)
        console.log(height)
        return
        })
    },
    methods:{
        imageClick(e){
            if(e.clientX==undefined){
                this.imagePostion =e.touches[0].clientX-this.handleDownClientX;
            }else{
                this.imagePostion = e.clientX;
            }
            this.imageMove=true;

        },
        changeCarousel(index){
            this.pageSize=index;
        },
        next(){
            this.pageSize++,
            this.$refs.carousel.next();
            this.$emit('getTitle',this.images[this.pageSize].title);
            this.$emit('getText',this.images[this.pageSize].text);
        },
        pre(){
            this.pageSize--,
            this.$refs.carousel.prev();
            this.$emit('getTitle',this.images[this.pageSize].title);
            this.$emit('getText',this.images[this.pageSize].text);
        },
         handleMove(e){
          //区域移动时触发
            if(this.flag){
                if(e.clientX==undefined){
                    this.x=e.touches[0].clientX-this.handleDownClientX;
                }else{
                    this.x=e.clientX-this.handleDownClientX;
                }
                // console.log("handleDownClientX"+this.handleDownClientX)
                // console.log("this.x"+this.x)
                // console.log("imgWidth"+this.imgWidth)
                if(this.x<-this.imgWidth/2){
                this.x=-this.imgWidth/2; 
                }
                if(this.x>this.imgWidth/2){
                    this.x=this.imgWidth/2; 
                }
                this.imageMove=false;
                
            }
            if(this.imageMove){
                if(e.clientX==undefined){
                    this.imagePostion -=e.touches[0].clientX-this.handleDownClientX;
                }else{
                    this.imagePostion -= e.clientX;
                }
                if(this.imagePostion<0){
                    this.pre();
                }else{
                    this.next();
                }
            }
                this.imageMove=false;
            
      },
      handleDown(e){
          //当鼠标指针移动到元素上方，并按下鼠标按键（左、右键均可）时，会发生 mousedown 事件
          this.flag = true;
          this.imageMove=false;
          if(this.handleDownClientX==0){
            if(e.clientX==undefined){
                this.handleDownClientX =e.touches[0].clientX-this.handleDownClientX;
            }else{
                this.handleDownClientX = e.clientX;
            }
            
          }
        
          console.log(e);
      },
        handleUp(e){
        //当在元素上松开鼠标按键（左、右键均可）时，会发生 mouseup 事件。
        //   this.x=e.clientX-this.handleDownClientX
        this.flag = false;
        this.imageMove = false;
      },
    },
    computed:{
            imgWidth(){
                return (this.screenWidth-100)<800?this.screenWidth-100:800;
            },
            getDivHeight(){
                return function(){
                    return document.getElementById('thatTitle').offsetHeight;
                }
            }
    },
    watch:{
        pageSize(newVal,oldVal) {
            if(newVal<0){
                this.pageSize=0
            }else if (newVal>this.images.length-1){
                this.pageSize=this.images.length-1
            }
            
            this.$refs.carousel.setActiveItem(this.pageSize);

        }
  }
}
</script>
<style lang="scss" scoped>
$imgSize: 800px;
// $imgSize: 100rem;
#imageComparator{
    display: flex;
}
.block-pageSize{
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EFEFEF;

    div{
        cursor:pointer;
        height: 2.875rem;
        font-family: MicrosoftYaHei;
        font-size: 1.125rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0.0625rem;
        color: #0098e9;
        border: solid 1px #dcdcdc;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.875rem;

    }
    .block-pageSize-end{
        width: 6.25rem;
        border:initial;
        color: initial;
    }
    .changePage{
        width: 7rem;
    }
}

.block-text{
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
	background-color: #ffffff;
	box-shadow: 0px 3px 7px 0px 
		rgba(0, 0, 0, 0.08);
    padding-left: 1.8rem;
}
.block-text :nth-child(1){
    display: flex;
    align-items: center;
	font-family: MicrosoftYaHei;
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #999999;

}

.block-text :nth-child(2){
	font-family: MicrosoftYaHei;
	font-size: 1.375rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #999999;
    flex: 1;
    display: flex;
    align-items: center;
}
.cmp-btn{
    color: rgba(255,255,255,0.38);
    position: absolute;
    bottom: 20%;
    height: 1.5rem;
    background: #29313A;
    border: 1px solid rgba(255,255,255,0.38);
    border-radius: 0.3rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    width: 3rem;
    line-height: 1.5rem;
    cursor: pointer;
    user-select: none;
}
.cmp-btn-text{
    margin-top: -0.0625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;
}
.block-img1{
    width: $imgSize;
    height: $imgSize;
}
.block-div1{
    position: absolute;
    // width: $imgSize;
    // height: $imgSize;
    // background-size: contain;
    // background-position:center center;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
}
.block-div2{
    position: absolute;
    width: $imgSize;
    height: $imgSize;
    overflow: hidden;
    margin-left: 200px;
    border-left: 1px solid #fff;
    background-size: 100%;
    width: 100%;
    height: 100%;
    user-select: none;

}
.block-img2{
    transform:translateX(-200px);
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    user-select: none;
}
.block{
    width: $imgSize;
    height: $imgSize;
}
.imageComparator-div1{
    flex: 1;
    text-align: right;
    margin:auto;//使其垂直居中
    img{
        cursor: pointer;
        margin-right: 30px;
    }
}
.imageComparator-div3{
    flex: 1;
    text-align: left;
    margin:auto;//使其垂直居中
    img{
        cursor: pointer;
        margin-left: 30px;
    }
}
</style>