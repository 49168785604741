import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import {
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    DropdownMenu,
    Dropdown,
    DropdownItem,
    Carousel,
    CarouselItem,
} from 'element-ui';

Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(DropdownMenu);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(Carousel);
Vue.use(CarouselItem);