import Vue from 'vue'
import App from './App.vue'
import router from './index.js' 
import './element.js' 
import './font.css'
import store from './store/index.js'

Vue.prototype.$store = store

Vue.config.productionTip = false



new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
