<template>
    <div id="share">
        <div class="share-div">
            <div class="share-div-img" :style="{borderRadius:shape?100+'px':'',backgroundImage:'url('+img+')'}">
            </div>
            <div class="text-one">
                {{appName}}微信公众号
            </div>
            <div class="text-two">
                扫描关注
            </div>
        </div>
    </div>
</template>
<script>
import valueGetters from "../../common/mixins/valueGetters.js";
export default {
    props:{
        shape: Boolean,
        img: String,
    },
  mixins:[valueGetters],
  data(){
      return{
        screenWidth:0,
        screenHegith:0,
        ifphone:false,
      }
  },
  mounted(){
    var clientWidth = document.body.clientWidth;
    var clientHegith = document.body.clientHegith;
    if(clientWidth<600){
        this.ifphone=true;
        this.screenWidth=clientWidth;
        this.screenHegith=clientHegith;
    }
  },
}
</script>
<style lang="scss" scoped>
    #share{
        padding: 1px;
    }
    .share-div{
        padding: 1px;
        height:580px;
        width: 80%;
        margin: 0 auto;
        background-image: url('../../static/background/background.png');
    }
    .share-div-img{
        width: 200px;
        height: 200px;
        margin: 80px auto 0 auto;
        background-color: azure;
        background-size: 100%;
    }
    .text-one{
        height: 15px;
        font-family: MicrosoftYaHei;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 3px;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
    }
    .text-two{
        height: 12px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 3px;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
    }
</style>