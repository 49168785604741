<template>
    <div id="protocol" :style="{}">
         <div class="title1" :style="{height:ifphone?120+'px':'',marginTop:ifphone?30+'px':''}">
            <div class="title">{{appName}}软件许可及服务协议</div>
        </div>
		<div  class="paragraph" :style="{width:ifphone?'95%':''}">
        <div class="title">
            尊敬的用户:
        </div>
		<div>欢迎你使用{{appName}}软件及服务！</div>
		<div>为使用{{appName}}软件（以下简称“本软件”）及服务，你应当阅读并遵守《{{appName}}软件许可及服务协议》（以下简称“本协议”）。请你务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，并选择接受或不接受。限制、免责条款可能以加粗形式提示你注意。</div>
		<div>除非你已阅读并接受本协议所有条款，否则你无权下载、安装或使用本软件及相关服务。你的下载、安装、使用、获取{{appName}}帐号、登录等行为即视为你已阅读并同意上述协议的约束。</div>
		<div>如果你未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并特别注意未成年人使用条款。</div>
		<div class="h1">一、协议范围</div>
		<div class="h2">1.1&nbsp;协议适用主体范围</div>
		<div>本协议是你与{{appName}}之间关于你下载、安装、使用、复制本软件，以及使用{{appName}}相关服务所订立的协议。</div>
		<div class="h2">1.2&nbsp;协议更新</div>
		<div>本协议约定{{appName}}与用户之间关于“{{appName}}”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、 登录、使用本服务的个人。本协议可由{{appName}}随时更新， 更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知。 用户可在{{appName}}软件的”关于“中查阅最新版协议条款。在{{appName}}修改协议条款后，如果用户不接受修改后的条款，请立即停止使用{{appName}}提供的服务，用户继续使用{{appName}}提供的服务将被视为接受修改后的协议。</div>
		<div class="h1">二、服务内容</div>
		<div>2.1&nbsp;本服务内容是指{{appName}}向用户提供兼职内容，以及完成任务而奖励用户的返利收益（以下简称“{{appName}}”）；具体服务内容由{{appName}}根据实际情况提供，包括但不限于：</div>
		<div>2.1.1&nbsp;广告播放：用户安装运行“{{appName}}”软件后可以在软件界面观看广告；</div>
		<div>2.1.2&nbsp;返利任务：用户安装运行“{{appName}}”软件后，可以在客户端内，获取的{{appName}}与第三方供应商、合作伙伴提供的广告信息。部分广告信息以任务返利的形式呈现给用户。包括但不限于下载应用、浏览广告信息；</div>
		<div>2.1.3&nbsp;钱币制：根据任务要求，完成任务的用户可获得奖励；</div>
		<div>2.1.4&nbsp;钱币兑换：用户可以使用所获钱币兑换{{appName}}的合作供应商所提供的商品及服务。</div>
		<div>2.2&nbsp;在遵守相关法律法规前提下，根据合作广告主的要求，{{appName}}有权决定用户在“{{appName}}”软件界面上看到的广告内容。</div>
		<div>2.3&nbsp;用户使用获得的钱币能够兑换的商品及服务的种类与兑换价格由{{appName}}全权决定。</div>
		<div class="h1">三、软件使用规则</div>
		<div class="h2">3.1&nbsp;软件的获取</div>
		<div>3.1.1&nbsp;你可以直接从得到{{appName}}授权的第三方获取；</div>
		<div>3.1.2&nbsp;如果你从未经{{appName}}授权的第三方获取本软件或与本软件名称相同的安装程序，{{appName}}无法保证该软件能够正常使用，并对因此给你造成的损失不予负责。</div>
		<div class="h2">3.2软件的安装与卸载</div>
		<div>3.2.1&nbsp;{{appName}}可能为不同的终端设备开发了不同的软件版本，你应当根据实际情况选择下载合适的版本进行安装。</div>
		<div>3.2.2&nbsp;下载安装程序后，你需要按照该程序提示的步骤正确安装。</div>
		<div>3.2.3&nbsp;如果你不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果你愿意帮助{{appName}}改进产品服务，请告知卸载的原因。</div>
		<div class="h2">3.3软件的更新</div>
		<div>3.3.1&nbsp;为了改善用户体验、完善服务内容，{{appName}}将不断努力开发新的服务，并为你不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）</div>
		<div>3.3.2&nbsp;为了保证本软件及服务的安全性和功能的一致性，{{appName}}有权不经向你特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。</div>
		<div>3.3.3&nbsp;本软件新版本发布后，旧版本的软件可能无法使用。{{appName}}不保证旧版本软件继续可用及相应的客户服务，请你随时核对并下载最新版本。</div>
		<div class="h1">四、账号注册</div>
		<div>4.1&nbsp;用户在使用本服务前需要注册一个“{{appName}}”账号。“{{appName}}”账号应当使用您的微信账号绑定注册， 请用户使用尚未与“{{appName}}”账号绑定的微信账号，以及未被{{appName}}根据本协议封禁的微信账号注册“{{appName}}”账号。 {{appName}}可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。</div>
		<div>4.2&nbsp;用户没有完成微信账号注册流程，仍然继续使用“{{appName}}”软件，系统将自动为该用户分配一个邀请码，该邀请码无需用配套密码登录。</div>
		<div>4.3&nbsp;如果注册申请者有被{{appName}}封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，{{appName}}将拒绝其注册申请。</div>
		<div>4.4&nbsp;注册成功后，用户可以通过“{{appName}}”软件中“我的账号”界面浏览或更改本人的个人信息。 但不能修改系统自动分配给用户的ID-“邀请码”。</div>
		<div>4.5&nbsp;用户注册时所填写的个人信息如有变动，用户需通过“我的账号”界面及时更改。 用户因未及时更改造成的损失{{appName}}概不负责。</div>
		<div>4.6&nbsp;{{appName}}账号的解释权归{{appName}}所有，用户完成申请注册手续后，仅获得{{appName}}账户的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖{{appName}}账号或者以其他方式许可非初始申请注册人使用{{appName}}账号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用{{appName}}账号。</div>
		<div>4.7&nbsp;用户注册{{appName}}账号后如果长期不登录该帐号，{{appName}}有权回收该帐号，以免造成资源浪费，由此带来的任何损失均由用户自行承担。</div>
		<div class="h1">五、完成任务，累计钱币</div>
		<div>5.1&nbsp;运行“{{appName}}”软件后，按照要求完成解锁、平台任务等均可获取钱币。</div>
		<div>5.2&nbsp;完成所有的体验任务，都需要连接网络；因包括但不限于网络稳定等问题而导致任务完成失败，未获得钱币，{{appName}}不会补发钱币。</div>
		<div>5.3&nbsp;部分需提交截图证明的任务，{{appName}}有权对任务截图进行审核，并根据你提交的任务截图对任务完成的结果做出通过或者不通过的评定。</div>
		<div>5.4&nbsp;你可以在“{{appName}}”软件中查看你获取的钱币情况。“{{appName}}”软件中显示的累计钱币数量可能与{{appName}}服务器端存在统计差异，实际钱币数量以服务器端为准。</div>
		<div>5.5&nbsp;用户钱币作为“{{appName}}”软件中的虚拟记录符号，不能在用户间相互转让， 不能使用包括人民币在内的任何法定货币购买，不具有在“{{appName}}”软件以外的环境中进行交易结算的功能。</div>
		<div>5.6&nbsp;如果用户的累计钱币数量出现错误，用户可以自出现错误之日起90天以内向{{appName}}提出更正要求， {{appName}}如果确定该用户的要求为正当合理，应在用户提出要求之日起90天以内更正错误。</div>
		<div class="h1">六、钱币兑换</div>
		<div>6.1&nbsp;用户使用钱币兑换商品及服务后，如出现商品及服务的质量问题，由{{appName}}合作商负责解决，{{appName}}不承担相关责任。由此产生的纠纷及诉讼与{{appName}}无关。</div>
		<div>6.2&nbsp;用户只有完成微信账号注册流程且绑定自己的真实手机号之后才能用获得的钱币兑换上述商品及服务。 仅有用户ID-邀请码而未完成微信账号注册且绑定自己的真实手机号的用户无法进行钱币兑换。</div>
		<div class="h1">七、用户个人信息保护</div>
		<div>7.1&nbsp;{{appName}}未经用户同意不向任何第三方公开、透露用户个人隐私信息。但以下特定情形除外：</div>
		<div>7.1.1&nbsp;{{appName}}根据法律法规规定或有权机关的指示提供用户的个人隐私信息；</div>
		<div>7.1.2&nbsp;由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏， 或其他非因{{appName}}原因导致的个人隐私信息的泄露；</div>
		<div>7.1.3&nbsp;用户自行向第三方公开其个人隐私信息；</div>
		<div>7.1.4&nbsp;用户与{{appName}}及合作单位之间就用户个人隐私信息的使用公开达成约定， {{appName}}因此向合作单位公开用户个人隐私信息；</div>
		<div>7.1.5&nbsp;何由于黑客攻击、电脑病毒侵入及其他非因{{appName}}原因导致用户个人隐私信息的泄露。</div>
		<div>7.2&nbsp;用户同意{{appName}}可在以下事项中使用用户的个人隐私信息：</div>
		<div>7.2.1&nbsp;向用户及时发送重要通知，如软件更新、本协议条款的变更；</div>
		<div>7.2.2&nbsp;{{appName}}内部进行审计、数据分析和研究等，以改进{{appName}}的产品、服务和与用户之间的沟通；</div>
		<div>7.2.3&nbsp;依本协议约定，{{appName}}管理、审查用户信息及进行处理措施；</div>
		<div>7.2.4&nbsp;适用法律法规规定的其他事项。</div>
		<div>7.3&nbsp;你在注册帐号或使用本服务的过程中，需要提供一些必要的信息，例如：你进行兑换时，需要你提供已实名认证的支付宝账号；若你提供的信息不完整，则无法使用本服务或在使用过程中受到限制。</div>
		<div>7.4&nbsp;{{appName}}非常重视对未成年人个人信息的保护。若你是18周岁以下的未成年人，在使用{{appName}}的服务前，应事先取得你家长或法定监护人的书面同意。</div>
		<div class="h1">八、主权利义务条款</div>
		<div class="h2">8.1&nbsp;账户使用规范</div>
		<div>8.1.1&nbsp;用户有责任妥善保管注册帐户信息及帐户密码的安全，用户需要对注册帐户以及密码下的行为承担法律责任。用户同意在任何情况下不向他人透露帐户及密码信息。当在你怀疑他人在使用你的帐号时，你应立即通知{{appName}}公司。</div>
		<div>8.1.2&nbsp;用户在安装{{appName}}后，尽快完成微信账号注册流程，因未完成邀请码与微信账号绑定而造成的账户钱币丢失，{{appName}}对此不负任何责任。</div>
		<div>8.1.3&nbsp;长期不运行“{{appName}}”软件且未完成手机号注册流程的用户（非活跃用户）所获得钱币自动被清零。 长期不运行指1个月（30天）内没有运行“{{appName}}”软件，即没有在“{{appName}}”软件内进行任何操作。</div>
    <div>8.1.4 “{{appName}}”软件使用过程中用户上传以及修复、转换等产生的图片的在线保存期限为：1. 对于使用VIP会员处理的图片在会员到期后保留30天，过期后会予以删除；2. 对于使用Pro卡处理的图片在修复后保留60天，过期后予以删除；3. 对于使用试用机会处理的图片保留7天，过期后予以删除。</div>
		<div>8.1.5 “{{appName}}”软件提供了账户注销功能，如果您不再使用本软件。可以到“我的”->“设置”界面中注销账户。注销后您登录本软件时产生的用户头像与昵称、第三方账号关联关系、会员资格、购买记录、图片处理记录等信息会被即时删除，不再予以记录，并且无法恢复，请谨慎使用该功能。</div>

		<div class="h2">8.2&nbsp;用户注意事项</div>
		<div>8.2.1&nbsp;你理解并同意：为了向你提供有效的服务，本软件会利用你终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。</div>
		<div>8.2.2&nbsp;你理解并同意{{appName}}将会尽其商业上的合理努力保障你在本软件及服务中的数据存储安全，但是，{{appName}}并不能就此提供完全保证，包括但不限于以下情形：</div>
		<div>8.2.2.1&nbsp;{{appName}}不对你在本软件及服务中相关数据的删除或储存失败负责；</div>
		<div>8.2.2.2&nbsp;{{appName}}有权根据实际情况自行决定单个用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。你可根据自己的需要自行备份本软件及服务中的相关数据；</div>
		<div>8.2.2.3&nbsp;如果你停止使用本软件及服务或服务被终止或取消，{{appName}}可以从服务器上永久地删除你的数据。服务停止、终止或取消后，{{appName}}没有义务向你返还任何数据。</div>
		<div>8.2.3&nbsp;用户在使用本软件及服务时，须自行承担如下来自{{appName}}不可掌控的风险内容，包括但不限于：</div>
		<div>8.2.3.1&nbsp;由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；</div>
		<div>8.2.3.2&nbsp;由于无线网络信号不稳定、无线网络带宽小等原因，所引起的{{appName}}登录失败、页面打开速度慢等风险。</div>
		<div class="h1">九、用户使用规范</div>
		<div>9.1&nbsp;{{appName}}用户不能利用{{appName}}账号或者{{appName}}提供的服务进行如下行为：</div>
		<div>9.1.1&nbsp;利用科技手段批量建立虚假账号；</div>
		<div>9.1.2&nbsp;侵害{{appName}}以及第三者的知识产权；</div>
		<div>9.1.3&nbsp;提交、发布虚假信息，或者盗用他人资料，冒充、利用他人名义；</div>
		<div>9.1.4&nbsp;未经{{appName}}许可，而传播广告或淫秽暴力等信息；</div>
		<div>9.1.5 处理涉及政治人物和事件的相关内容；</div>
		<div>9.1.6&nbsp;其他不遵守本协议的行为；</div>
		<div>9.1.7&nbsp;其他违法以及不正当的行为；</div>
		<div>9.2&nbsp;如果用户采用擅自更改“{{appName}}”软件程序或通过刷机刷量等技术手段邀请虚假用户或在没有充分告知其他潜在用户的情况下使得该潜在用户虽然安装了“{{appName}}”软件但没有正常使用行为的或使用黑客手段攻击{{appName}}服务器等不正当手段来获取钱币， {{appName}}视为用户作弊行为而有权取消该用户资格并删除该用户所有钱币， 并保留对攻击{{appName}}服务器等黑客行为追究法律责任的权力。</div>
		<div class="h1">十、免责条款</div>
		<div>10.1&nbsp;{{appName}}提供的服务中包括，用户同意在使用过程中显示{{appName}}与第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责， 对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，{{appName}}不承担任何责任。</div>
		<div>10.2&nbsp;当用户接受该协议时，用户应当明确了解并同意：</div>
		<div>（1）“{{appName}}”软件程序不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。“{{appName}}”软件程序是在现有技术基础上提供的服务。“{{appName}}”软件程序不保证以下事项∶ A、“{{appName}}”软件程序将符合所有用户的要求； B、“{{appName}}”软件程序不受干扰、能够及时提供、安全可靠或免于出错； C、本服务使用权的取得结果是正确或可靠的。</div>
		<div>（2）是否经由“{{appName}}”软件程序下载或取得任何资料，由用户自行考虑、衡量并且自负风险，因下载任何资料而导致用户手机的任何损坏或资料流失，用户应负完全责任。希望用户在使用“{{appName}}”软件程序时，小心谨慎并运用常识；</div>
		<div>（3）用户经由“{{appName}}”软件程序取得的广告、建议和资讯，无论其形式或表现，绝不构成本协议未明示规定的任何保证；</div>
		<div>（4）基于以下原因而造成的利润、商誉、使用、资料损失或其它无形损失，“{{appName}}”软件程序不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使“{{appName}}”软件程序已被告知前款赔偿的可能性）： A、“{{appName}}”软件程序的使用或无法使用； B、用户的传输或资料遭到未获授权的存取或变更； C、“{{appName}}”软件程序中任何第三方之声明或行为； D、“{{appName}}”软件程序在服务交易中为用户提供的广告所导致的行为； E、“{{appName}}”软件程序其它相关事宜。</div>
		<div>（5）“{{appName}}”软件程序只是为用户提供一个展示服务平台，对于第三方供应商、合作伙伴提供的广告合法性、真实性及其品质，“{{appName}}”软件程序一律不负任何担保责任；</div>
		<div>（6）“{{appName}}”软件程序提供与其它互联网上的网站或资源的链接，用户可能会因此连结至其它运营商经营的网站，但不表示“{{appName}}”软件程序与这些运营商有任何关系。其它运营商经营的网站均由各经营者自行负责，不属于“{{appName}}”软件程序控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，“{{appName}}”软件程序亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，“{{appName}}”软件程序不负任何直接或间接的责任。</div>
		<div class="h1">十一、服务的变更、中断、终止</div>
		<div>11.1&nbsp;因设备维修或更换、故障和通信中断等技术原因而中断业务，{{appName}}可视情况事前或事后通知用户。</div>
		<div>11.2&nbsp;如果因{{appName}}临时中断业务而导致用户钱币数据丢失，{{appName}}会给予丢失数据的用户相应的钱币补偿， 具体补偿钱币数量由{{appName}}决定。但因不可抗力导致的业务中断发生时,{{appName}}不给予用户补偿。</div>
		<div>11.3&nbsp;当{{appName}}用户发生如下情况时，{{appName}}可单方面终止本协议，取消用户继续使用{{appName}}产品及服务的资格：</div>
		<div>11.3.1&nbsp;用户死亡；</div>
		<div>11.3.2&nbsp;盗用他人的个人信息或手机；</div>
		<div>11.3.3&nbsp;注册用户时提供虚假信息；</div>
		<div>11.3.4&nbsp;以非法手段（包括且不限于黑客攻击等）来积累或使用钱币；</div>
		<div>11.3.5&nbsp;妨碍其他用户的正常使用；</div>
		<div>11.3.6&nbsp;伪称是{{appName}}的工作人员或管理人员；</div>
		<div>11.3.7&nbsp;擅自强行更改{{appName}}的计算机系统，或威胁侵入系统；</div>
		<div>11.3.8&nbsp;擅自传播谣言，用各种手段来毁损{{appName}}的名誉与妨碍{{appName}}的正常营业；</div>
		<div>11.3.9&nbsp;利用{{appName}}的产品及服务宣传垃圾广告；</div>
		<div>11.3.10&nbsp;其他违反本协议的行为及违法行为。</div>
		<div>11.3.11&nbsp;用户如因上述第11.3.1条原因而被取消用户资格，用户法定财产继承人可在用户资格被取消之日起30日内向{{appName}}提出书面申请，将该用户剩余钱币转移至自己所有的{{appName}}账号下， 否则{{appName}}有权删除该用户剩余钱币。</div>
		<div>11.3.12&nbsp;用户如因上述第11.3.2条到11.3.10条的原因而被取消用户资格，{{appName}}有权立即删除该用户的全部钱币。</div>
		<div class="h1">十二、知识产权声明</div>
		<div>12.1&nbsp;{{appName}}是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，{{appName}}享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</div>
		<div>12.2&nbsp;未经{{appName}}或相关权利人书面同意，你不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</div>
		<div class="h1">十三、其他</div>
		<div>13.1&nbsp;本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和{{appName}}之间发生任何纠纷或争议， 首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交{{appName}}住所地有管辖权的人民法院管辖。</div>
		<div>13.2&nbsp;本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</div>
		<div>13.3&nbsp;本协议的版权由{{appName}}所有，{{appName}}保留一切解释和修改的权力。</div>
		<div>13.4&nbsp;本协议从2020年11月26日起适用。</div>
		</div>
    </div>
</template>
<script>
import valueGetters from "../../common/mixins/valueGetters.js";
export default {
  mixins:[valueGetters],
    data(){
        return {
            ifphone:false,
        }
    },
    mounted(){
        var screenWidth = document.body.clientWidth;
        console.log(screenWidth);
        if(screenWidth<1200){
            this.ifphone=true;
        }
    }
}
</script>
<style lang="scss" scoped>
$lineHeight:12.5rem;
.paragraph{
    margin: 0 auto;
    width: 1197px;
	font-family: MicrosoftYaHei;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 1px;
	color: #808080;
}
.text-title{
	height: 1.5625rem;
	font-family: MicrosoftYaHei;
	font-size: 1.5rem;
	font-weight: bold;
	font-stretch: normal;
	line-height: 2.25rem、;
	letter-spacing: 1px;
	color: #404040;
    margin: 1.25rem 0 10px 0;
}
.text-title-phone{
	height: 1.125rem;
	font-family: MicrosoftYaHei;
	font-size: 1.125rem;
	font-weight: bold;
	font-stretch: normal;
	line-height: 2.25rem;
	letter-spacing: 1px;
	color: #404040;
    margin: 1.25rem 0 1.25rem 0;
}
.h1{
    margin-top: 30px;
	font-family: MicrosoftYaHei;
    line-height: 40px;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 14px;
}
.h2{
    margin-top: 20px;
	font-family: MicrosoftYaHei;
	font-size: 20px;
    line-height: 20px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 14px;

}
.title{
    margin-top: 24px;
	font-family: MicrosoftYaHei;
	font-size: 24px;
    line-height: 36px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 14px;
}
.h2-phone{
    height: 1.2rem;
	font-family: MicrosoftYaHei;
	font-size: 1.2rem;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 1.2rem;
}
#protocol{
    width: 100%;
    margin-bottom: 1rem;
}
.context-phone{
    margin: 0 auto;
    width: 100%;
	font-family: MicrosoftYaHei;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 1px;
	color: #808080;
    div{
        margin-top: 10px;
    }
}
.context{
    margin: 0 auto;
    width: 66%;
	font-family: MicrosoftYaHei;
	font-size: 1.125rem;
	font-weight: normal;
	font-stretch: normal;
	line-height: 2rem;
	letter-spacing: 1px;
	color: #808080;
}
.title1 :nth-child(1){
    height: 2.125rem;;
    font-family: MicrosoftYaHei;
    font-size: 2.125rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #333333;

}
.title1{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    height: 10rem;
    width: 100%;
    text-align: center;
	font-family: MicrosoftYaHei;
	font-size: 2.125rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
    div{
        flex:1;
        height: 1.18rem;
        font-family: MicrosoftYaHei;
        font-size: 1.25rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
        color: #333333;
    }
}

.toptext{
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #333333;
}
</style>