<template>
    <div id="privacy" :style="{height:ifphone?'':''}">
        <div class="title1" :style="{height:ifphone?120+'px':'',marginTop:ifphone?30+'px':''}">
            <div :style="{fontSize:ifphone?20+'px':''}">{{appName}}隐私政策</div>
            <div :style="{fontSize:ifphone?20+'px':''}">（v1.0.1）</div>
            <div :style="{fontSize:ifphone?20+'px':''}">2021.08.24</div>
        </div>
        	<div class="paragraph" :style="{width:ifphone?'95%':''}">
                <div class="title">
                    尊敬的用户:
                </div>
                <div class="toptext">{{companyName}}（简称“我们”，联系电话0512-65685970）作为{{appName}}
                    软件平台（简称“平台”）的运营者，深知个人信息对您的重
                    要性，我们将按照法律法规的规定，保护您的个人信息及隐
                    私安全。我们制定本“隐私政策”并特别提示：希望您在使用
                    平台及相关服务前仔细阅读并理解本隐私政策，以便做出
                    适当的选择。</div>
                <div class="title">本隐私政策将帮助您了解：</div>
                <div>我们会遵循隐私政策收集、使用您的信息，但不会仅因您
                    同意本隐私政策而采用强制捆绑的方式一揽子收集个人信
                    息。</div>
                <div>当您使用或开启相关功能或使用服务时，为实现功能、服
                    务所必需，我们会收集、使用相关信息。除非是为实现基本
                    业务功能或根据法律法规要求所必需的必要信息，您均可
                    以拒绝提供且不影响其他功能或服务。我们将在隐私政策
                    中逐项说明哪些是必要信息。</div>
                <div>如果您未登录账号，我们会通过设备对应的标识符信息
                    来保障信息传输的基本功能。如果您登录了账号，我们会根
                    据账号信息实现信息传输。</div>
                <div><strong>通讯录、精确地理位置、摄像头、麦克风、相册权限</strong>，均
                    不会默认开启，只有经过您的明示授权才会在为实现特定
                    功能或服务时使用，您也可以撤回授权。特别需要指出的
                    是，即使经过您的授权，我们获得了这些敏感权限，也不会
                    在相关功能或服务不需要时而收集您的信息。平台权限使
                    用与使用情况说明</div>
                <div>本隐私政策适用于您通过平台应用程序、平台官方网
                    站、供第三方网站和应用程序使用的平台软件开发工具包
                    （SDK)和应用程序编程接口（API)方式来访问和使用我们
                    的产品和服务。</div>
                <div>下文将帮您详细了解我们如何收集、使用、存储、传输、共
                    享、转让（如适用）与保护个人信息；帮您了解查询、访问、
                    删除、更正、撤回授权个人信息的方式。其中，有关您个人
                    信息权益的条款重要内容我们已用加粗形式提示，请特别
                    关注。</div>
                <div>1.我们如何收集和使用个人信息</div>
                <div>2.我们如何使用Cookie等同类技术</div>
                <div>3.我们如何共享、转让、公开披露个人信息</div>
                <div>4.我们如何存储个人信息</div>
                <div>5.我们如何保护个人信息的安全</div>
                <div>6.管理您的个人信息</div>
                <div>7.未成年人条款</div>
                <div>9.隐私政策的修订和通知</div>
                <div>10.联系我们</div>
                <div  class="title">1.我们如何收集和使用个人信息</div>
                <div>我们会按照如下方式收集您在使用服务时主动提供的,以
                    及通过自动化手段收集您在使用功能或接受服务过程中产
                    生的信息:</div>
                <div class="h1">1.1 注册、登录、认证</div>
                <div class="h2">1.1.1 注册、登录帐号</div>
                <div>a.当您注册、登录平台及相关服务时，您可以通过手机号创
                    建帐号，并且您可以完善相关的<strong>网络身份识别信息（头像、
                    昵称、密码)</strong>，收集这些信息是为了帮助您完成注册。您还
                    可以根据自身需求选择填写<strong>性别、生日、地区及个人介绍</strong>来
                    完善您的信息。</div>
                <div>b.您也可以使用第三方帐号登录并使用平台,您将授权我
                    们获取您在<strong>第三方平台注册的公开信息（头像、昵称以及您
                    授权的其他信息)</strong>,用于与平台帐号绑定，使您可以直接登
                    录并使用本产品和相关服务。在使用第三方账号进行登录
                    时,可能需要将实现登录所必需的信息在剪切板中写入与
                    读取。这些信息仅供实现登录相关的目的所使用，不会收集
                    您的隐私信息。</div>
                <div>c.登录第三方帐号：当您使用平台帐号登录第三方帐号时，
                    经过您的同意，我们可能会共享昵称、头像以及您授权的其
                    他信息。</div>
                <div>d.基于我们与通信运营商的合作，当您使用平台“一键登
                    录”功能时，经过您的明示同意，运营商会将您的<strong>手机号码</strong>
                    发送给我们，便于我们为您提供快捷的登录服务。手机号
                    码属于个人敏感信息，如果拒绝提供将无法使用“一键登
                    录”方式注册登录平台,但不影响您通过其他方式注册登
                    录,也不影响其他功能的正常使用。</div>
                <div  class="h2">1.1.2 认证</div>
                <div>在您使用身份认证的功能或相关服务所需时，根据相关法
                    律法规，您可能需要提供您的<strong>真实身份信息（真实姓名、身
                    份证号码、电话号码等)</strong>以完成实名验证。部分信息属于个
                    人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法
                    获得相关服务,但不影响其他功能与服务的正常使用。</div>
                <div class="h2">1.1.3 消息通知</div>
                <div>您知悉并同意，对于您在使用产品及/或服务的过程中提供
                    的您的<strong>联系方式(例如:联系电话)</strong>，我们在运营中可能会
                    向其中的一种或多种发送多类通知,用于用户消息告知、身
                    份验证、安全验证;如您不愿接受这些信息，
                  您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。</div>
                <div class="h1">1.2 运营与安全运行</div>
                <div class="h2">1.2.1 运营与安全保障</div>
                <div>我们致力于为您提供安全、可信的产品与使用环境,提供优
                    质而可靠的服务与信息是我们的核心目标。</div>
                <div class="h2">1.2.2 设备信息与日志信息</div>
                <div>a. 设备信息。当你使用本应用时，为了保障您正常使用本应用服务，更准确定位并解决您在使用本应用服务时遇到的问题，改进及优化本应用的服务体验，保障您的帐号安全，我们会收集<strong>设备属性信息（例如您的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、国际移动用户识别码IMSI、网络设备硬件地址MAC、广告标识符IDFA、Android ID、匿名设备标识符OAID），设备位置信息（如通过GPS、蓝牙或Wi-Fi信号获得的位置信息）、设备连接信息（浏览器的类型、电信运营商、使用的语言）以及设备状态信息（例如设备传感器数据，设备应用安装列表）</strong>。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。</div>
                <div>b.为了预防恶意程序、保障运营质量及效率,我们会收集安 装的应用信息或正在运行的<strong>进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、 性能数据、应用来源</strong>。</div>
                <div>c.我们可能使用您的<strong>账户信息、设备信息、服务日志信息以 及我们关联方、合作方在获得您授权或依法可以共享的信息</strong>，用于判断账户安全、进行身份验证、检测及防范安全事件</div>
                <div>d.为了保证用户数据不丢失，以及优化应用体验，我们再您将应用切换到后台时，仍然会收集您的<strong>设备信息（设备属性信息（例如您的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA、广告标识符IDFA、Android ID、匿名设备标识符OAID），设备位置信息（如通过GPS、蓝牙或Wi-Fi信号获得的位置信息）、设备连接信息（浏览器的类型、电信运营商、使用的语言）以及设备状态信息（例如设备传感器数据，设备应用安装列表)</strong></div>
                <div class="h1">1.3 我们间接收集的您的信息</div>
                <div>本应用接入了丰富的第三方服务（如微信支付）。当您使用本应用接入的第三方服务时，可能需要提交您的个人信息。我们将基于您的授权将该服务所必要的信息提供给为您提供服务的第三方，同时基于您的授权向第三方获取必要的相关信息。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策</div>
                <div class="h2">1.3.1 微信分享、支付SDK</div>
                <div>信息获取</div>
                <div>设备号、IP地址、设备软件信息、设备识别码设备标识符、所在地区、网络使用习惯、设备相关应用信息以及其他相关信息</div>
                <div>使用目的</div>
                <div>社交平台分享、支付</div>
                <div><a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">点击查看该SDK的隐私政策全文</a></div>
                <div class="h2">1.3.2 TBS腾讯浏览服务</div>
                <div>信息获取</div>
                <div>设备号、设备软件信息、设备识别码设备标识、设备相关应用信息以及其他相关信息</div>
                <div>使用目的</div>
                <div>优化应用体验</div>
                <div><a href="https://rule.tencent.com/rule/preview/1c4e2b4b-d0f6-4a75-a5c6-1cfce00a390d">点击查看该SDK的隐私政策全文</a></div>
                <div class="h2">1.3.3 支付宝SDK</div>
                <div>信息获取</div>
                <div>设备号、IP地址、设备软件信息、设备识别码设备标识符、所在地区、网络使用习惯、设备相关应用信息以及其他相关信息</div>
                <div>使用目的</div>
                <div>用于支付宝付款</div>
                <div><a href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132">点击查看该SDK的隐私政策全文</a></div>
                <div class="h1">1.4 分享与活动参与</div>
                <div>在您分享或接收被分享的信息、参加活动等情形下,我们需
                    要访问您的<strong>剪切板，读取其中包含、链接，以实现跳转、分
                    享、活动联动等功能或服务</strong>。</div>
                <div class="h1">1.5 统计分析</div>
                <div>为了分析应用新增、激活、留存、性能等统计性指标，我们
                    可能会调用剪切板对相关统计信息进行归因分析,请您放
                    心，我们不会收集您的隐私信息。</div>
                <div class="h1">1.6 收集、使用个人信息目的变更</div>
                    <div>请您了解，随着我们业务的发展，可能会对平台的功能和提
                    供的服务有所调整变化。原则上，当新功能或服务与我们当
                    前提供的功能或服务相关时，收集与使用的个人信息将与
                    原处理目的具有直接或合理关联。在与原处理目的无直接
                    或合理关联的场景下，我们收集、使用您的个人信息，会再
                    次进行告知,并征得您的同意。</div>
                <div class="h1">1.7 依法豁免征得同意收集和使用的个人信息</div>
                    <div>请您理解,在下列情形中,根据法律法规及相关国家标准,
                    我们收集和使用您的个人信息无需征得您的授权同意：</div>
                <div>a.与国家安全、国防安全直接相关的；</div>
                <div>b.与公共安全、公共卫生、重大公共利益直接相关的；</div>
                <div>c.与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
                <div>d.出于维护个人信息主体或其他个人的生命、财产等重大合
                    法权益但又很难得到本人同意的；</div>
                <div>e.所收集的您的个人信息是您自行向社会公众公开的；</div>
                <div>f.从合法公开披露的信息中收集的您的个人信息的,如合法
                    的新闻报道、政府信息公开等渠道；</div>
                <div>g.根据您的要求签订或履行合同所必需的；</div>
                <div>h.用于维护软件及相关服务的安全稳定运行所必需的，例如
                    发现、处置软件及相关服务的故障;</div>
                <div>i.为合法的新闻报道所必需的;</div>
                <div>j.学术研究机构基于公共利益开展统计或学术研究所必要,
                    且对外提供学术研究或描述的结果时，对结果中所包含的
                    个人信息进行去标识化处理的;</div>
                <div>k.法律法规规定的其他情形。</div>
                <div>特别提示您注意,如信息无法单独或结合其他信息识别到
                    您的个人身份，其不属于法律意义上您的个人信息；当您的
                    信息可以单独或结合其他信息识别到您的个人身份时或我
                    们将无法与任何特定个人信息建立联系的数据与其他您的
                    个人信息结合使用时，这些信息在结合使用期间，将作为您
                    的个人信息按照本隐私政策处理与保护。</div>
                
                <div class="title">2.我们如何使用Cookie等同类技术</div>
                <div><strong>Cookie和设备信息标识</strong>等同类技术是互联网中普遍使用
                    的技术。当您使用平台及相关服务时，我们可能会使用相关
                    技术向您的设备发送一个或多个Cookie 或匿名标识符,以
                    收集、标识您访问、使用本产品时的信息。我们承诺，不会
                    将Cookie用于本隐私政策所述目的之外的任何其他用途。
                    我们使用Cookie 和同类技术主要为了实现以下功能或服
                    务：</div>
                <div class="h1">2.1 保障产品与服务的安全、高效运转</div>
                <div>我们可能会设置认证与保障安全性的Cookie 或匿名标识
                    符，使我们确认您是否安全登录服务，或者是否遇到盗用、
                    欺诈及其他不法行为。这些技术还会帮助我们改进服务效
                    率，提升登录和响应速度。</div>
                <div class="h1">2.2 帮助您获得更轻松的访问体验
                    使用此类技术可以帮助您省去重复您填写个人信息、输入
                    搜索内容的步骤和流程(示例:记录搜索、表单填写)。</div>
                <div class="h1">2.3 Cookie的清除</div>
                <div>大多数浏览器均为用户提供了清除浏览器缓存数据的功
                    能，您可以在浏览器设置功能中进行相应的数据清除操
                    作。如您进行清除，您可能无法使用由我们提供的、依赖于
                    Cookie的服务或相应功能。</div>
                <div class="title">3.我们如何共享、转让、公开披露个人信息</div>
                <div class="h1">3.1共享</div>
                <div class="h2">3.1.1 共享原则</div>
                <div>a.授权同意原则:未经您的同意，我们不会共享您的个人信
                    息，除非共享的个人信息是去标识化处理后的信息，且共享
                    第三方无法重新识别此类信息的自然人主体。如果第三方
                    使用信息的目的超越原授权同意范围,他们需要重新征得
                    您的同意。</div>
                <div>b.合法正当与最小必要原则：共享的数据必须具有合法正
                    当目的，且共享的数据以达成目的必要为限。</div>
                <div>c.安全审慎原则：我们将审慎评估第三方使用共享信息的
                    目的，对这些合作方的安全保障能力进行综合评估，并要求
                    其遵循合作法律协议。我们会对合作方获取信息的软件工
                    具开发包(SDK)、应用程序接口（API）进行严格的安全监
                    测，以保护数据安全。</div>
                <div>接入第三方SDK目录</div>
                <div class="h2">3.1.2 实现功能或服务的共享信息</div>
                <div>a.当您在使用平台中由我们的关联方、第三方提供的功能,
                    或者当软件服务提供商、智能设备提供商、系统服务提供商
                    与我们联合为您提供服务时我们会将实现业务所必需的信
                    息与这些关联方、第三方共享,用于手机验证码登录、微信或
                  其它第三方平台登录时的头像昵称信息同步。本软件不会利用这些
                  做推荐算法、偏好分析。</div>
                <div>b.小程序：当您使用小程序时，未经您同意，我们不会向这
                    些开发者、运营者共享您的个人信息。当您使用小程序时,
                    小程序可能会使用您授权的相关系统权限，您可以在小程
                    序中撤回授权。</div>
                <div>c.地理位置服务：当您使用地理位置相关服务时，我们会通
                    过SDK或相关技术将GPS信息与位置服务提供商(高德地
                    图）进行共享以便可以向您返回位置结果。GPS信息是个人
                    敏感信息，拒绝提供，仅会影响地理位置服务功能，但不影
                    响其他功能的正常使用。</div>
                <div>d支付功能:支付功能由与我们合作的第三方支付机构向您
                    提供服务。第三方支付机构可能需要收集您的姓名、银行
                    卡类型及卡号、有效期及手机号码。银行卡号、有效期及手
                    机号码是个人敏感信息,这些信息是支付功能所必需的信
                    息，拒绝提供将导致您无法使用该功能，但不影响其他功能
                    的正常使用。</div>
                <div class="h2">3.1.3 实现广告相关的共享信息</div>
                <div>a.广告投放:我们可能与进行推广和广告投放的合作
                    伙伴共享信息，但我们不会共享用于识别您个人身份的信
                    息(姓名、身份证号），仅会向这些合作伙伴提供不能识别
                    您个人身份的间接画像标签及去标识化的设备信息或匿名
                    化后的设备、网络、渠道等信息，以帮助其在不识别您个人
                    身份的前提下提升广告有效触达率。</div>
                <div>b.广告统计：我们可能与业务的服务商、供应商和其他合作
                    伙伴共享分析去标识化的设备信息或统计信息,这些信息
                    难以或无法与您的真实身份相关联。这些信息将帮助我们
                    分析、衡量广告和相关服务的有效性。</div>
                <div  class="h2">3.1.4 实现安全与分析统计的共享信息</div>
                <div>a.保障使用安全:我们非常重视帐号、服务及内容安全，为
                    保障您和其他用户的帐号与财产安全,使您和我们的正当
                    合法权益免受不法侵害,我们和关联方或服务提供商可能
                    会共享必要的设备、帐号及日志信息。</div>
                <div>b.分析产品使用情况：为分析我们服务的使用情况，提升用
                    户使用的体验,可能会与关联方或第三方共享产品使用情
                    况（崩溃、闪退）的统计性数据，这些数据难以与其他信息
                    结合识别您的个人身份。</div>
                <div>c.学术研究与科研:为提升相关领域的科研能力，促进科技
                    发展水平，我们在确保数据安全与目的正当的前提下，可能
                    会与科研院所、高校等机构共享去标识化或匿名化的数据。</div>
                <div  class="h2">3.1.5 帮助您参加营销推广活动</div>
                <div>当您选择参加我们及我们的关联方或第三方举办的有关营
                    销活动时，可能需要您提供姓名、通信地址、联系方式、银
                    行帐号等信息。其中部分信息是个人敏感信息，拒绝提供可
                    能会影响您参加相关活动，但不会影响其他功能。只有经过
                    您的同意,我们才会将这些信息与关联方或第三方共享,以
                    保障您在联合活动中获得体验一致的服务，或委托第三方
                    及时向您兑现奖励。</div>
                <div  class="h1">3.2 转让</div>
                <div>a.我们不会转让您的个人信息给任何其他第三方,除非征得
                    您的明确同意。</div>
                <div>b.随着我们业务的持续发展，我们将有可能进行合并、收
                    购、资产转让，您的个人信息有可能因此而被转移。在发生
                    前述变更时，我们将按照法律法规及不低于本隐私政策所
                    载明的安全标准要求继受方保护您的个人信息，否则我们
                    将要求继受方重新征得您的授权同意。</div>
                <div  class="h1">3.3 公开展示</div>
                <div>a.我们不会公开披露您的信息，除非遵循国家法律法规规
                    定或者获得您的同意。我们公开披露您的个人信息会采用
                    符合行业内标准的安全保护措施。</div>
                <div>b.对违规帐号、欺诈行为进行处罚公告时，我们会披露相
                    关帐号的信息。</div>
                <div class="h1">3.4 依法豁免征得同意共享、转让、公开披露的个人信息
                    请您理解,在下列情形中,根据法律法规及国家标准，我们
                    共享、转让、公开披露您的个人信息无需征得您的授权同
                    意：</div>
                <div>a.与国家安全、国防安全直接相关的;</div>
                <div>b.与公共安全、公共卫生、重大公共利益直接相关的;</div>
                <div>c.与犯罪侦查、起诉、审判和判决执行等直接相关的;</div>
                <div>d.出于维护您或其他个人的生命、财产等重大合法权益但又
                    很难得到本人同意的;</div>
                <div>e.您自行向社会公众公开的个人信息;</div>
                <div>f.从合法公开披露的信息中收集个人信息的,如合法的新闻
                    报道、政府信息公开等渠道。</div>
                <div class="title">4.我们如何存储个人信息</div>
                <div class="h1">4.1 存储地点</div>
                <div>我们依照法律法规的规定,将在境内运营过程中收集和产
                    生的您的个人信息存储于中华人民共和国境内。目前，我们
                    不会将上述信息传输至境外,如果我们向境外传输,我们将
                    会遵循相关国家规定或者征求您的同意。</div>
                <div class="h1">4.2 存储期限</div>
                <div>我们仅在为提供平台及服务之目的所必需的期间内保留您
                    的个人信息，您未撤回、删除或未注销帐号期间，我们会保
                    留相关信息。超出必要期限后,我们将对您的个人信息进行
                    删除或匿名化处理,但法律法规另有规定的除外
                    <div class="title">5.我们如何保护个人信息的安全</div>
                    <div>a.我们非常重视您个人信息的安全,将努力采取合理的安全
                        措施(包括技术方面和管理方面)来保护您的个人信息，防
                        止您提供的个人信息被不当使用或在未经授权的情况下被
                        访问、公开披露、使用、修改、损坏、丢失或泄漏。</div>
                    <div>b.我们会使用不低于行业同行的加密技术、匿名化处理及
                        相关合理可行的手段保护您的个人信息，并使用安全保护
                        机制防止您的个人信息遭到恶意攻击。</div>
                    <div>c.我们会建立专门的安全部门、安全管理制度、数据安全流
                        程保障您的个人信息安全。我们采取严格的数据使用和访
                        问制度,确保只有授权人员才可访问您的个人信息，并适时
                        对数据和技术进行安全审计。</div>
                    <div>d.尽管已经采取了上述合理有效措施，并已经遵守了相关法
                        律规定要求的标准,但请您理解,由于技术的限制以及可能
                        存在的各种恶意手段，在互联网行业，即便竭尽所能加强安
                        全措施，也不可能始终保证信息百分之百的安全，我们将尽
                        力确保您提供给我们的个人信息的安全性。</div>
                    <div>e.您知悉并理解,您接入我们的服务所用的系统和通讯网
                        络，有可能因我们可控范围外的因素而出现问题。因此，我
                        们强烈建议您采取积极措施保护个人信息的安全，包括但
                        不限于使用复杂密码、定期修改密码、不将自己的帐号密码
                        及相关个人信息透露给他人等。</div>
                    <div>f.我们会制定应急处理预案，并在发生用户信息安全事件时
                        立即启动应急预案，努力阻止这些安全事件的影响和后果
                        扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们
                        将按照法律法规的要求，及时向您告知：安全事件的基本情
                        况和可能的影响、我们已经采取或将要采取的处置措施、您
                        可自主防范和降低风险的建议、对您的补救措施。我们将及
                        时将事件相关情况以邮件、信函、短信及相关形
                        式告知您，难以逐一告知时，我们会采取合理、有效的方式
                        发布公告。同时，我们还将按照相关监管部门要求，上报用
                        户信息安全事件的处置情况。</div>
                    <div>g.您一旦离开平台及相关服务，浏览或使用其他网站、服务
                        及内容资源，我们将没有能力和直接义务保护您在平台及
                        相关服务之外的软件、网站提交的任何个人信息，无论您登
                        录、浏览或使用上述软件、网站是否基于平台的链接或引
                        导。</div>
                    <div class="title">6.管理您的个人信息</div>
                    <div>我们非常重视您对个人信息的管理,并尽全力保护您对于
                        您个人信息的查询、访问、修改、删除、撤回同意授权、注
                        销帐号、投诉举报以及设置隐私功能的相关权利，以使您有
                        能力保障您的隐私和信息安全。</div>
                </div>
                <div class="h1">6.2 改变或撤回授权范围</div>
                <div class="h2">6.2.1 改变或撤回敏感权限设置</div>
                <div>a.您可以在设备本身操作系统中关闭精确地理位置信息、通
                    讯录、摄像头、麦克风、相册权限、日历权限，改变同意范
                    围或撤回您的授权。撤回授权后我们将不再收集与这些权
                    限相关信息。</div>
                <div class="h2">6.2.2 改变或撤回授权的信息处理</div>
                <div>特定的业务功能和服务将需要您的信息才能得以完成,当
                    您撤回同意或授权后，我们无法继续为您提供撤回同意或
                    授权所对应的功能和服务,也不再处理您相应的个人信息。
                    但您撤回同意或授权的决定，不会影响我们此前基于您的
                    授权而开展的个人信息处理。</div>
                <div class="h1">6.3 访问、删除、更正您的个人信息</div>
                <div class="h2">6.3.1 访问个人帐号信息</div>
                您可以查询、访问您的头像、用户名、简介、性别、生日、地
                区,您可以在【我】-【编辑资料】进行查询、访问、更正。
                <div class="h1">6.4注销帐号</div>
                <div>在您注销帐号前，我们将验证您的个人身份、安全状态、设
                备信息等。有关注销的流程和内容，注销帐号的行为是不可
                逆的行为，当您注销帐号后，我们将删除有关您的相关信息
                或进行匿名化处理,但法律法规另有规定的除外。如有需要请到
                “我的“>”设置“界面中操作。</div>
            <div class="h1">6.5 投诉举报</div>
            <div>您可以按照我们公示的制度进行投诉或举报。如果您认为
                您的个人信息权利可能受到侵害,或者发现侵害个人信息
                权利的线索，您可以进入用户反馈界面与我们联系。我们核
                查后会在30日内反馈您的投诉与举报。</div>
            <div class="h1">6.6 访问隐私政策</div>
            <div>a.您可以在注册页面，或者在登录个人帐号【我】-【设
                置】-【隐私政策】查看本隐私政策的全部内容。</div>
            <div>b.请您了解,本隐私政策中所述的平台及相关服务可能会
                根据您所使用的手机型号、系统版本、软件应用程序版本、
                移动客户端等因素而有所不同。最终的产品和服务以您所
                使用的平台软件及相关服务为准。</div>
            <div class="h1">6.7 停止运营向您告知</div>
            <div>如我们停止运营，我们将及时停止收集您个人信息的活动，
                将停止运营的通知以逐一送达或公告的形式通知您,并对
                所持有的您的个人信息进行删除或匿名化处理。</div>
            <div class="title">7.未成年人条款</div>
            <div class="h1">7.1未成年人通用条款</div>
            <div>a.若您是未满18周岁的未成年人，在使用平台及相关服务
                前,应在您的父母或其他监护人监护、指导下共同阅读并同
                意本隐私政策。</div>
            <div>b.我们根据国家相关法律法规的规定保护未成年人的个人
                信息，只会在法律允许、父母或其他监护人明确同意或保护
                未成年人所必要的情况下收集、使用、共享或披露未成年
                人的个人信息；如果我们发现在未事先获得可证实的父母
                或其他监护人同意的情况下收集了未成年人的个人信息,
                则会设法尽快删除相关信息。同时我们建立了严格的未成
                年人信息收集使用规则，以保护儿童和青少年个人信息安
                全,您可以通过阅读《儿童/青少年使用须知》了解更具体
                内容</div>
            <div>c.若您是未成年人的监护人，当您对您所监护的未成年人的
                个人信息有相关疑问时，请通过本隐私政策公示的联系方
                式与我们联系。</div>	
            <div class="title">8.隐私政策的修订和通知</div>
            <div>a.为了给您提供更好的服务,平台及相关服务将不时更新与
                变化，我们会适时对本隐私政策进行修订，这些修订构成本
                隐私政策的一部分并具有等同于本隐私政策的效力，未经
                您明确同意，我们不会削减您依据当前生效的本隐私政策
                所应享受的权利。</div>
            <div>b.本隐私政策更新后，我们会在平台发出更新版本，并在
                更新后的条款生效前通过其他适当的方式提醒您更新的内
                容,以便您及时了解本隐私政策的最新版本。</div>
            <div class="title">9.设备权限调用
                读取手机识别码
                获取设备信息辅助作为用户相关标识
                读取存储空间
                用来读取和存储照片进行图片修复</div>
            <div class="title">10.其他</div>
            <div>1、本协议的效力、解释及纠纷的解决，适用于中华人民共
                和国法律。若用户和本公司之间发生任何纠纷或争议，首先
                应友好协商解决,协商不成的,用户同意将纠纷或争议提交
                本公司住所地有管辖权的人民法院管辖。</div>
            <div>2、本协议的任何条款无论因何种原因无效或不具可执行
                性，其余条款仍有效，对双方具有约束力。</div>
            <div>本协议最终解释权归本公司所有,如本协议中任何一条被
                视为废止、无效或者因任何理由不可执行时,该条应视为可
                分且并不影响其他剩余任何条款的有效性和可执行性。</div>
        </div>
	</div>
</template>
<script>
import valueGetters from "../../common/mixins/valueGetters.js";
export default {
    data(){
        return {
            ifphone:false,
        }
    },
  mixins:[valueGetters],
    mounted(){
        var screenWidth = document.body.clientWidth;
        console.log(screenWidth);
        if(screenWidth<1200){
            this.ifphone=true;
        }
    }
}
</script>
<style lang="scss" scoped>
$lineHeight:12.5rem;
.paragraph{
    margin: 0 auto;
    width: 1197px;
	font-family: MicrosoftYaHei;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 1px;
	color: #808080;
}
.text-title{
	height: 1.5625rem;
	font-family: MicrosoftYaHei;
	font-size: 1.5rem;
	font-weight: bold;
	font-stretch: normal;
	line-height: 2.25rem、;
	letter-spacing: 1px;
	color: #404040;
    margin: 1.25rem 0 10px 0;
}
.text-title-phone{
	height: 1.125rem;
	font-family: MicrosoftYaHei;
	font-size: 1.125rem;
	font-weight: bold;
	font-stretch: normal;
	line-height: 2.25rem;
	letter-spacing: 1px;
	color: #404040;
    margin: 1.25rem 0 1.25rem 0;
}
.h1{
    margin-top: 30px;
	font-family: MicrosoftYaHei;
    line-height: 40px;
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 14px;
}
.h2{
    margin-top: 20px;
	font-family: MicrosoftYaHei;
	font-size: 20px;
    line-height: 20px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 14px;

}
.title{
    margin-top: 24px;
	font-family: MicrosoftYaHei;
	font-size: 24px;
    line-height: 36px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 14px;
}
.h2-phone{
    height: 1.2rem;
	font-family: MicrosoftYaHei;
	font-size: 1.2rem;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    margin-bottom: 1.2rem;
}
#privacy{
    width: 100%;
    margin-bottom: 1rem;
}
.context-phone{
    margin: 0 auto;
    width: 100%;
	font-family: MicrosoftYaHei;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 1px;
	color: #808080;
    div{
        margin-top: 10px;
    }
}
.context{
    margin: 0 auto;
    width: 66%;
	font-family: MicrosoftYaHei;
	font-size: 1.125rem;
	font-weight: normal;
	font-stretch: normal;
	line-height: 2rem;
	letter-spacing: 1px;
	color: #808080;
}
.title1 :nth-child(1){
    height: 2.125rem;;
    font-family: MicrosoftYaHei;
    font-size: 2.125rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #333333;

}
.title1{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    height: 18rem;
    width: 100%;
    text-align: center;
	font-family: MicrosoftYaHei;
	font-size: 2.125rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
    div{
        flex:1;
        height: 1.18rem;
        font-family: MicrosoftYaHei;
        font-size: 1.25rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
        color: #333333;
    }
}

.toptext{
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: #333333;
}

strong{
  color:black;
}
</style>