<template>
    <div id="foot" :style="{height:ifphone?15+'rem':''}">
        <div class="foot-div"  :style="{width:ifphone?23+'rem':'',paddingTop:ifphone?3+'rem':''}">
            <div class="foot-div-one" :style="{width:ifphone?23+'rem':''}">
                <div class="foot-div-one-div1" :style="{fontSize:ifphone?0.75+'rem':'',width:ifphone?'':'12rem'}">
                    <div ><router-link class="item" @click.native="goback"  to="/Protocol" >隐私政策</router-link></div>
                    <div ><router-link class="item" @click.native="goback" to="/Privacy" >用户协议</router-link></div>
                </div>
                <div class="foot-div-one-div2" :style="{fontSize:ifphone?0.75+'rem':''}">
                    <div>地址：{{address}}</div>
                    <div>电话：{{tel}}</div>
                </div>
            </div>
            <div class="foot-div-two" :style="{fontSize:ifphone?0.60+'rem':''}">
                <div >©2021 - {{ companyName }}. All rights reserved. 版权所有.<a style="color:#fff" target="_blank" href="http://beian.miit.gov.cn/">备案号：{{ icpNo }}</a>
                <br>
                  <div class="gong-an-bei-an">
                    <img src="@/static/ico/gongan.png" width="18px" height="18px">
                    <span style="margin: 0 0 0 12px"><a href="https://beian.mps.gov.cn/#/query/webSearch">苏公网安备32050602011932号</a></span>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import valueGetters from "../../common/mixins/valueGetters.js";
export default {
    props:{
        ifphone:Boolean,
    },
    mixins:[valueGetters],
    data(){
        return{
            screenWidth:0,
        }
    },
    mounted(){
        var screenWidth = document.body.clientWidth;
        console.log(screenWidth);
        if(screenWidth<750){
            // this.ifphone=true;
            this.screenWidth=screenWidth;
        }
    },
    methods:{
        goback(){
           document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }
    
}
</script>
<style lang="scss" scoped>
.gong-an-bei-an{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
}
.gong-an-bei-an>span>a{
  color: white;
}

.item{
    text-decoration: none; 
    color: #fff;;
}
.foot-div-two{
    font-size: 0.82rem;
    text-align: center;
    margin-top: 1.18rem;
}
.foot-div-two :nth-child(1){
    line-height: 1rem;
}
.foot-div-two :nth-child(2){
    margin-top: 1rem;
}
.foot-div-one-div1 :nth-child(1){
    flex: 1;
}
.foot-div-one-div1 :nth-child(2){
    flex: 1;
}
.foot-div-one-div1{
    line-height: 1.75rem;
    flex-direction: column;
    display: flex;
    width:4rem;
    justify-content: center;

}

.foot-div-one-div2 :nth-child(1){
    
}
.foot-div-one-div2 :nth-child(2){
    flex: 1;
}
.foot-div-one-div2{
    flex: 1;
    line-height: 1.75rem;
    flex-direction: column;
    display: flex;
}
#foot{
    height: 10.8rem;
    width: 100%;
    background-color: #262627;
    font-size: 0.5rem;
}
.foot-div-one{
    font-size: 1rem;
    display: flex;
    width: 38rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 1.5rem;
    margin: 0 auto;

}
.foot-div{
    padding-top: 2rem;
    color: #fff;
    margin: 0 auto;
}
</style>