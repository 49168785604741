<template>
  <div id="Navbar">
      <div class="topTitle"  v-if="!ifphone" >
           <div class="shrinkage1"   v-if="screenWidth<1200" >
                <el-dropdown>
                    <img src="@/static/Navbar/reduce.png" alt="">
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="item" :style="{color:index==0?'#007bbd':''}" to="/index" @click.native="index=0" ><el-dropdown-item>首页</el-dropdown-item></router-link>
                        <router-link class="item" :style="{color:index==1?'#007bbd':''}" to="/Protocol"  @click.native="index=1"><el-dropdown-item>用户协议</el-dropdown-item></router-link>
                        <router-link class="item" :style="{color:index==2?'#007bbd':''}" to="/Privacy"  @click.native="index=2"><el-dropdown-item>隐私政策</el-dropdown-item></router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- 电脑 -->
            <div class ="title-pc">
                <div class="title-pc-left" :style="{marginLeft:screenWidth<1460?screenWidth<1200?60+'px':screenWidth-1100+'px':''}">
                    <div class="title-pc-left-1"  :style="{backgroundImage:'url('+require('@/static/ico/icon.png')+')',backgroundSize:'100% 100%',borderRadius:'13%'}"></div>
                    <div class="title-pc-left-2">
<!--                        <img height="100%" width="100%" src="@/static/font/font.svg" alt="">-->
                      {{appName}}
                    </div>
                    <div class="title-pc-left-3" :style="{marginLeft:screenWidth<1460?'0'+px:screenWidth-1200+'px'}">
                        <router-link @click.native="changeIndex(0)" v-if="screenWidth>1200" class="item"   to="/index" >首页</router-link>
                    </div>
                    <div class="title-pc-left-4">
                        <router-link @click.native="changeIndex(1)" v-if="screenWidth>1200" class="item"  to="/Protocol" >用户协议</router-link>
                    </div>
                    <div class="title-pc-left-5">
                        <router-link @click.native="changeIndex(2)" v-if="screenWidth>1200" class="item"  to="/Privacy" >隐私政策</router-link>
                    </div>
                </div>
                <!-- <div><span :style="{marginRight:screenWidth>1200?508+'px':'0',paddingLeft:5+'px',lineHeight:''}">老照片修复精灵</span></div> -->
                    
            </div>
           
      </div>
      <!-- 手机 -->
      <div v-if="ifphone" class ="title" :style="{height:!ifphone?150+'px':'',marginLeft:'1.8rem',fontSize:!ifphone?36+'px':''}">
          <div class="title-pc-left-1-imge"   :style="{backgroundImage:'url('+require('@/static/ico/icon.png')+')',backgroundSize:'100% 100%',borderRadius:'13%'}"></div>
            <div class="title-pc-left-2-imge">
                <img height="100%" width="100%" src="@/static/font/font.svg" alt="">
            </div>
      </div>
      <div class="shrinkage"  v-if="ifphone">
          <el-dropdown>
            <img width="30px" height="20px" src="@/static/Navbar/reduce.png" alt="">
            <el-dropdown-menu slot="dropdown">
                <router-link class="item"  to="/index" @click.native="index=0" ><el-dropdown-item>首页</el-dropdown-item></router-link>
                <router-link class="item"  to="/Protocol"  @click.native="index=1"><el-dropdown-item>用户协议</el-dropdown-item></router-link>
                <router-link class="item"  to="/Privacy"  @click.native="index=2"><el-dropdown-item>隐私政策</el-dropdown-item></router-link>
            </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div  class="imgdiv"  v-if="!ifphone">
          <div class="imgdiv1" :style="{backgroundImage:'url('+img[index].left+')'}"></div>
          <div class="imgdiv2-pc"  :style="{backgroundImage:'url('+img[index].pc+')',height:!ifphone?'300px':'160px'}">
                <div class="imgdiv2-pc-middle" :style="{paddingLeft:index!=0?screenWidth<1080?'':'200px':''}">
                    <div class="imgdiv2-left-div">
                         <div>
                            <div class="imgdiv2-left-div-top" :style="{backgroundImage:'url('+img[index].element+')'}">
                                AI智能修复·老照片
                            </div>
                            <div  @click="downLoad()" class="imgdiv2-left-div-bottom">APP版</div>
                        </div>
                    </div>
                    <div class="imgdiv2-right-div" :style="{backgroundImage:'url('+img[index].element2+')',width:''}">
                        <div :style="{backgroundImage:'url('+img[index].elementMiddleRight+')'}">
                        </div>
                        <div >
                        </div>
                    </div>
                </div>
          </div>
          <div class="imgdiv3" :style="{backgroundImage:'url('+img[index].right+')'}"></div>
      </div>
      <div  class="imgdiv"  v-if="ifphone">
          <div class="imgdiv1" :style="{backgroundImage:'url('+img[index].left+')'}"></div>
          <div class="imgdiv2"  :style="{backgroundImage:'url('+img[index].phone+')',height:!ifphone?'150px':'160px'}">
               <div>
                    <div class="imgdiv2-left-div-top" :style="{backgroundImage:'url('+img[index].element+')',height:6.9+'rem',width:16+'rem',fontSize:1.4+'rem',margin: '0 auto',marginTop:1.8+'rem'}">
                        AI智能修复·老照片
                    </div>
                 <div class="mobile-download-container">
                   <div  @click="downLoad()" class="imgdiv2-left-div-bottom" :style="{marginTop:1+'rem',height:2.5+'rem',width:10+'rem',fontSize:1.1+'rem'}">APP版</div>
                   <div  @click="miniprogram()" class="imgdiv2-left-div-bottom" :style="{marginTop:1+'rem',height:2.5+'rem',width:10+'rem',fontSize:1.1+'rem'}">小程序版</div>
                 </div>

                </div>
          </div>
          <div class="imgdiv3" :style="{backgroundImage:'url('+img[index].right+')'}"></div>
      </div>
      <!-- <div  class="imgdiv"  v-if="!ifphone">
          <div class="imgdiv1" :style="{backgroundImage:'url('+require('@/static/Navbar/1px.png')+')'}"></div>
          <div class="imgdiv2"  :style="{backgroundImage:'url('+img[index].pc+')',height:!ifphone?'150px':'160px'}">
               <div>老照片专业修复</div>
               <div :style="{width:'100%',textAlign:'center'}">&emsp;&emsp;{{img[index].text}}</div>
          </div>
          <div class="imgdiv3" :style="{backgroundImage:'url('+require('@/static/Navbar/1px.png')+')'}"></div>
      </div>
      <div  class="imgdiv"  v-if="ifphone">
          <div class="imgdiv1" :style="{backgroundImage:'url('+require('@/static/Navbar/1px.png')+')'}"></div>
          <div class="imgdiv2"  :style="{backgroundImage:'url('+img[index].phone+')',height:!ifphone?'150px':'160px'}">
              <div>老照片专业修复</div>
              <div :style="{width:'80%',textAlign:'center'}">&emsp;&emsp;是一款残破模糊老照片修复上色，可以将老照片，破损褶皱痕迹修复，并针对面部进行高度还原...</div>
          </div>
          <div class="imgdiv3" :style="{backgroundImage:'url('+require('@/static/Navbar/1px.png')+')'}"></div>
      </div> -->
  </div>
</template>


<script type="text/javascript">
import valueGetters from "../common/mixins/valueGetters.js";
export default {
  name: 'Navbar',
  props:{
    screenWidth:Number,
    ifphone:Boolean
  },
  components: {

  },
  mixins:[
    valueGetters
  ],
  data(){
    return {
        index:0,
        img:[
            {
                pc:require('@/static/Navbar/banner-shouye-pc.png'),
                phone:require('@/static/Navbar/banner-shouye-phone.png'),
                left:require('@/static/Navbar/banner-shouye-left.png'),
                right:require('@/static/Navbar/banner-shouye-right.png'),
                element:require('@/static/Navbar/element.png'),
                element2:require('@/static/Navbar/element2.png'),
                elementMiddleRight:'',
                text:"是一款残破模糊老照片修复上色，可以将老照片，破损褶皱痕迹修复，并针对面部进行高度还原...",
                title:''
            },
            {
                pc:require('@/static/Navbar/banner-yh-pc.png'),
                phone:require('@/static/Navbar/banner-yh-phone.png'),
                left:require('@/static/Navbar/banner-yh-left.png'),
                right:require('@/static/Navbar/banner-yh-right.png'),
                element:require('@/static/Navbar/element.png'),
                element2:'',
                elementMiddleRight:require('@/static/Navbar/element-yh.png'),
                text:"使用人工智能技术,可以将模提照片、低清晰度照片修复、还原",
                title:'用户协议'
            },
            {
                pc:require('@/static/Navbar/banner-yh-pc.png'),
                phone:require('@/static/Navbar/banner-yh-phone.png'),
                left:require('@/static/Navbar/banner-yh-left.png'),
                right:require('@/static/Navbar/banner-yh-right.png'),
                element:require('@/static/Navbar/element.png'),
                element2:'',
                elementMiddleRight:require('@/static/Navbar/element-ys.png'),
                text:"提供照片优化功能,修复过度曝光、夜间拍摄、雾天拍摄的照片等功能",
                title:'隐私政策'
            }
        ]
    }
  },
  methods: {
      changeIndex(index){
          this.index=index;
      },
    miniprogram(){
        let that = this;
        window.location = 'https://api.xiufulaozhaopian.com/marketing/miniprogram/url'
    },

      downLoad(){
        var ua = window.navigator.userAgent.toLowerCase();
        var link = document.createElement('a');

        var s = "";
            var isisiphone = ua.match(/iphone/i) == 'iphone';
            var ishuawei = ua.match(/huawei/i) == 'huawei';
            var ishonor = ua.match(/honor/i) == 'honor';
            var isoppo = ua.match(/oppo/i) == 'oppo';
            var isoppoR15 = ua.match(/pacm00/i) == 'pacm00';
            var isvivo = ua.match(/vivo/i) == 'vivo';
            var ismi = ua.match(/mi\s/i) == 'mi';
            var ismix = ua.match(/mix\s/i) == 'mix';
            var isredmi = ua.match(/redmi/i) == 'redmi';
            var issamsung = ua.match(/sm-/i) == 'sm-';
            if(isisiphone){

            }else if(ishonor || ishuawei){//华为
                link.href="market://details?id=com.repair.photopay";
            }else if(isoppo || isoppoR15){//oppo
                link.href="market://details?id=com.repair.photopay";
            }else if(isvivo){//vivo
                link.href="market://details?id=com.repair.photopay";
            }else if(ismi || isredmi || ismix){//小米
                link.href="market://details?id=com.repair.photopay";
            }else if(issamsung){
                link.href="market://details?id=com.repair.photopay";
            }else{
                link.href="https://static.pedesx.com/download/APKS/lzpxf/18/lzpxf_website.apk";
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeAttribute(link);
        }
  },
  mounted(){
  },
  computed:{
  }
}
</script>
<style lang="scss" scoped>
$font-stack: Helvetica, sans-serif;
$height: 4.25rem;
$heightRem: 4.25;
a{
    color: black;
}
.router-link-active{
    color: #215ba0;
}
.item{
    text-decoration: none;
}
.imgdiv2-pc-middle{
    height: 300px;
    width: 800px;
    margin: 0 auto;
}
.imgdiv2-right-div{
    width: 474px;
    height: 300px;
    float: left;
    background-position: center bottom;
    background-repeat: no-repeat;
    display: flex;

}
.imgdiv2-right-div :nth-child(1){
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
}
.imgdiv2-right-div :nth-child(2){
    flex: 1;
  

}
.imgdiv2-left-div{
    float: left;
    margin-top: 50px;
    width: 300px;
    height: 200px;

}
.imgdiv2-left-div-top{
    height: 138px;
    width: 300px;
    font-size: 28px;
    background-repeat: no-repeat;
    background-position: center ;
    font-family: PingFangSC-Medium;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 4px;
	color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% ;
}
.mobile-download-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}
.imgdiv2-left-div-bottom{
    margin: 0 auto;
    margin-top: 12px;
    height: 50px;
    width: 200px;
	font-size: 22px;
	background-image: linear-gradient(-21deg, 
		#c79e59 0%, 
		#d7b070 50%, 
		#e4bf82 100%), 
	linear-gradient(
		#f4cb87, 
		#f4cb87);
	background-blend-mode: normal, 
		normal;
	border-radius: 25px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC-Medium;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 3px;
    cursor: pointer;
}


.title-pc-left{
    margin-top: 5px;
    height: 70px;
    margin-left: 380px;
}
.title-pc-left-2-imge{
    transform: translateX(0.5rem);
    width: 13rem;
    height: $height;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    margin-top: 0.3rem;

}
.title-pc-left-1-imge{
    width:  3.7rem;
    height: 3.7rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;

}
.title-pc-left-1{
    margin-top: 2px;
    width: 66px;
    height: 66px;
    float: left;
}
.title-pc-left-2{
    transform: translateX(14px);
    display: flex;
    justify-content: center;
    align-content: center;
    float: left;
    width: 217px;
    margin-top: 2px;
    height: 66px;
    font-family: 'FangZhengZhengHei';
	font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 70px;
	letter-spacing: 1px;
	color: #333135;
 
}
.title-pc-left-3{
    line-height: 70px;
    margin-left: 511px;
    float: left;
	font-family: MicrosoftYaHei;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;

}

.title-pc-left-4{
    line-height: 70px;
    margin-left: 65px;
    float: left;
	font-family: MicrosoftYaHei;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;

}
.title-pc-left-5{
    line-height: 70px;
    margin-left: 65px;
    float: left;
	font-family: MicrosoftYaHei;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
}


.imgdiv2 :nth-child(1){
  
}
.imgdiv2 :nth-child(2){

}
.topTitle{
    height: 80px;
    width: 100%;
    overflow: hidden;
}
.shrinkage1{
    margin-right: 3rem;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}
.shrinkage{
    margin-top: 1.5rem;
    margin-right: 3rem;
    width: 2rem;
    height: 1.8rem;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Navbar{
}
.title-pc{
    text-align:center;
	height: 50px;
	font-family: MicrosoftYaHei-Bold;
	font-size: 30px;
	font-weight: 600;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: $height;
    text-align:center;
    width: 50%;
	height: $height;
	font-family: MicrosoftYaHei-Bold;
	font-size: 2.25rem;
	font-weight: 600;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    float: left;
}
.others{
	height: 70px;
    line-height: 70px;
	font-family: MicrosoftYaHei;
	font-size:  18px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #333333;
    float: left;
    display: flex;
    div{
        line-height: $height;
        margin-left: 66px;
    }
    .other1{
       margin-left: 508px;
    }
}
.item1{

}
.item2{

}
.item3{

}
.imgdiv{
    display: flex;
    clear: both;
}
.imgdiv-img{
    width: auto;
    max-height: 100%;

}
.imgdiv1{
    flex: 1;
    background-repeat: repeat-x;
}

.imgdiv2-pc{
    width: 1920px;
    height: 320px;
    background-size: auto  100% ;
    background-position: center center;
    background-repeat:no-repeat;


}
.imgdiv2{
    width: 1920px;
    height: 320px;
    background-size: auto  100% ;
    background-position: center center;
    background-repeat:no-repeat;


}
.imgdiv3{
    flex: 1;
}
</style>
