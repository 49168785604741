
/*
*  适用于vuex，根据传入的属性名称自动生成简单setter函数。
*  注意：如果有子模块，这个函数要放在子模块里用。示例：
*  ```javascript
*  const mutations = {
*       ...flatMutations(['propertiyA','propertiyB','propertyC'])	
*   }
*  ```
*  以上代码等效于：
* ```javascript
*  const mutations = {
*	propertyA(state, value){
*		state.propertyA = value;
*		},
*	propertyB(state, value){
*		state.propertyA = value;
*		},
*	propertyC(state, value){
*		state.propertyA = value;
*		},
*	}
* ```
* * 注意：由于微信小程序不支持new Function()语法，因此只能用state[curr]=>value
* 的方式代替。
* 
*  @param names：数组，对应state里面的属性名
*  @return 返回setters函数的对象，key是函数名。如果names为空或者不是数组，则返回{}。
*  @author roger.wang
*  @since 1.0.0
*  @date 2022/04/11
* 
*/

export function flatMutations(names){
	if(Array.isArray(names)){
		let ret = names.reduce((acc, curr)=>{
			Object.defineProperty(acc, curr, {value:new Function('state','value',`state['${curr}']=value;`), enumerable: true})
			return acc;
		},{});
		return ret;
	}else{
		return {};
	}
}


/*
*  适用于vuex，根据传入的模块名和属性名称自动生成简单getter函数。可用于批量生成getter。
*  注意，如果用于生成全局getter，module必须传值。如果module为空指针或空字符串，则是为本
*  模块的属性生成getter。示例：
* ```javascript
* const getters = {
*	...flatGetters('moduleS',['propertyA','propertyB']),
*   ...flatGetters('',['propertyC'])
*	}
* ```
* 以上代码等效于：
* ```javascript
* const getters = {
*	propertyA:state=>state.moduleS.propertyA,
*	propertyB:state=>state.moduleS.propertyB,
*	propertyC:state=>state.propertyC,
*	}
* ```
* 注意：由于微信小程序不支持new Function()语法，因此只能用state=>state[module][name]
* 的方式代替。
* 
*  @param module：getter对应数据所属的子模块名称
*  @param names：数组，对应子模块state里面的属性名
*  @return 返回getters函数的对象，key是函数名。如果names为空或者不是数组，则返回{}。
*  @author roger.wang
*  @since 1.0.0
*  @date 2022/04/11
*/
// #ifndef MP-WEIXIN
export function flatGetters(module, names){
	// console.log(`flatGetters got names: ${names}`)
	if(Array.isArray(names)){
		console.log("names: Array")
		let ret = names.reduce((acc, curr)=>{
			let code = '';
			if(typeof module == 'undefined' || module == null || module == ''){
				code = `return state.${curr};`;
			}else{
				code = `return state.${module}.${curr};`;
			}
			Object.defineProperty(acc, curr, {value:new Function('state', code), enumerable:true});
			return acc;
		}, {});
		return ret;
    }else{
		return {};
	}
}
// #endif

/*
*  适用于vuex，根据names生成对应的actions。默认在每个name的action里调用同名commit。
*  asyncOps中的key指定每个同名action所作的异步操作。如果asyncOps中没有定义，则直接
*  认为操作成功，该action返回一个只会resolve的Promise。flatActions会向asyncOps中的
*  同名函数提供异步Promise所需的resolve和reject对象，以及store相关的上下context和
*  新值value。asyncOps中的同名函数必须调用resolve或reject以便完成操作。
* 
*  @param names:Array state中属性名称的数组
*  @param asyncOps:Object state中相应属性名称作为key，value是这个属性对应的异步操作函数
*  该函数的形参列表为：(resolve, reject, context, value)
* 
*  @author roger.wang
*  @since 1.0.1
*  @date 2022/04/11
*  @update 2023/3/4 如果有指定asyncOps，就不做默认的简单commit操作
*/
export function flatActions(names, asyncOps){
	if(Array.isArray(names)){
		let ret = names.reduce((acc, curr)=>{
			Object.defineProperty(acc, curr, {value:(context, value)=>{
				if(asyncOps!=null && (curr in asyncOps) && typeof asyncOps[curr] == 'function'){
					// 如果有给定curr属性所对应的异操作函数，再返回一个Promise，在Promise里执行异步操作
					return new Promise((resolve, reject)=>{
						// 调用该属性名对应的函数操作，该传入的函数必须处理resolve或reject
						asyncOps[curr](resolve, reject, context, value);
					});
				}else{
					// 如果curr对应的属性没有给定相应的异步操作函数，则执行简单的commit操作
					return new Promise((resolve, reject)=>{
						// 先执行简单的commit
						try{
							context.commit(curr, value);
							resolve();
						}catch(e){
							console.error(e);
							reject(e);
						}
					});
				}

			}, enumerable: true})
			return acc;
		},{})
		return ret;
	}else{
		return {};
	}
}

/**
 * 把obj里的键值对，当作vuex的state数据用commit方法做赋值
 * @param {Function} commit
 * @param {Object} obj
 * @author roger.wang
 * @since 1.0.0
 * @date 2023/03/2
 */
export function objectToCommit(commit, obj){
	// console.log(`commiting: ${JSON.stringify(obj)}`)
	Object.keys(obj).forEach((elem)=>{
		commit(elem, obj[elem]);
	})
}

export function arrayToState(names){
	return {
		...names.reduce((acc,curr)=>{
			Object.defineProperty(acc, curr, {value: false, enumerable: true, writable: true});
			return acc;
		},{})
	}
}

/*
 * 把回调式的调用改为Promise。
 * 例如：someFunction({success, fail}) -> callbackToPromise(options) : Promise
 * 传给someFunction的原success和fail只要非null仍会被执行，reslove是在其后执行的。
 * @param {Function} target 要调用的目标函数，这个函数的参数规格必须是{success,fail}
 * @param {Object} options 要传给目标函数的参数
 * @return {Promise} 目标函数的succes回调的参数就是resolve的值，reject以此类推。
 * @author roger.wang
 * @since 1.0.0
 * @date 2023/7/1
 * 
 */
export function callbackToPromise(target, options){
    return new Promise((resolve, reject)=>{
        let oldSuccess = options!=null?options.success:null;
        let oldFail = options!=null?options.fail:null;
        let newSuccess = function(res){
            if(oldSuccess!=null
                && typeof oldSuccess != 'undefined'
                && typeof oldSuccess == 'function'){
                oldSuccess(res);
            }
            resolve(res);
        }
        let newFail = function(err){
            if(oldFail!=null
                && typeof oldFail != 'undefined'
                && typeof oldFail == 'function'){
                oldFail(err);
            }
            reject(err);
        }
        newSuccess.bind(target);
        newFail.bind(target);
        if(options == null){
            options = new Object();
        }
        options.success = newSuccess;
        options.fail = newFail;
        target(options);
    })
}


/*
 * 一键生成每个Vuex模块的清除所有当前数据的Action函数。Action的名字可以在
 * 每个模块的定义里自己调整。
 * @param {Array} names 要清除的数据的名称数组。如果你使用的是矩步引领公司
 * 的Vuex规范，直接写模块内定义的names即可。因为按照规范在每个Module的头部
 * 都用names数组标记了该模块的所有数据名称。
 * @author roger.wang
 * @since 1.0.0
 * @date 2023/7/1
 */
export function resetGenerator(names){
	return function({commit}){
		return Promise.all(names.reduce((acc,curr)=>{
				let p = new Promise((resolve)=>{
					commit(curr,null);
					resolve()
				})
				acc.push(p);
				return acc;
				},[])	
			)
	}
}

/**
 * 产生从min到max之间的共num个不重复的随机整数
 * @param {Object} min
 * @param {Object} max
 * @param {Object} num
 */
export function randomIntegers(min, max, num){
	let arr = new Array
	while(arr.length<num){
		let r = Math.floor(Math.random() * max + min)
		if(arr.indexOf(r)<0){
			arr.push(r)
		}
	}
	return arr;
}