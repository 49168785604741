<template>
    <div id="downLoad" :style="{height:!ifphone?'80px':'8rem'}">
       <div class="dashed">
            <div class="button" @click="downLoad()" :style="{width:!ifphone?'320px':'20rem',height:!ifphone?'50px':'3.125rem'}">
            <div class="button-div" >
                <div  class="div-none"></div>
                <div class="div-img" >
                    <img src="@/static/Download/downLoad.png" :style="{height:!ifphone?'':'1.5rem',marginRight:!ifphone?'':'-0.8rem'}"/>
                </div>
                <div class="div-text" :style="{fontSize:!ifphone?'':'1.5rem'}">立即下载</div>
            </div> 
        </div>
       </div>
    </div>
</template>
<script>
export default {
    props:{
        ifphone:Boolean,
        screenWidth:Number, 
    },
    mounted(){
    },
    data(){
        return{
        }
    },
    methods:{
        downLoad(){
        var ua = window.navigator.userAgent.toLowerCase();
        var link = document.createElement('a');

        var s = "";
            var isisiphone = ua.match(/iphone/i) == 'iphone';
            var ishuawei = ua.match(/huawei/i) == 'huawei';
            var ishonor = ua.match(/honor/i) == 'honor';
            var isoppo = ua.match(/oppo/i) == 'oppo';
            var isoppoR15 = ua.match(/pacm00/i) == 'pacm00';
            var isvivo = ua.match(/vivo/i) == 'vivo';
            var ismi = ua.match(/mi\s/i) == 'mi';
            var ismix = ua.match(/mix\s/i) == 'mix';
            var isredmi = ua.match(/redmi/i) == 'redmi';
            var issamsung = ua.match(/sm-/i) == 'sm-';
            if(isisiphone){

            }else if(ishonor || ishuawei){//华为
                link.href="market://details?id=com.repair.photopay";
            }else if(isoppo || isoppoR15){//oppo
                link.href="market://details?id=com.repair.photopay";
            }else if(isvivo){//vivo
                link.href="market://details?id=com.repair.photopay";
            }else if(ismi || isredmi || isredmi){//小米
                link.href="market://details?id=com.repair.photopay";
            }else if(issamsung){
                link.href="market://details?id=com.repair.photopay";
            }else{
                link.href="https://static.pedesx.com/download/APKS/lzpxf/18/lzpxf_website.apk";
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeAttribute(link);
        }
    }
}
</script>
<style lang="scss" scoped>
    $buttonLine:3.5rem;
    .dashed{
        margin-left: 2rem;
        margin-right: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8.16rem;
        width:100rem;
        border-radius: 0.625rem;
	    // border: dashed 0.125rem #7a7a7a;
    }
    #downLoad{
        background-color: #ffffff;
        height: 14.375rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button{
        display: flex;
        height: $buttonLine;
        background-color: #007bbd;
        cursor: pointer;
        border-radius: 30px;
        line-height: $buttonLine;
        justify-content: center;
        align-items: center;
        .button-div{
            height: $buttonLine;
            display: flex;
            .div-text{
                width: 84px;
                font-size: 20px;
                font-family: MicrosoftYaHei;
                font-weight: normal;
	            font-stretch: normal;
                color: #ffffff;
                letter-spacing: 1px;
            }
            .div-img{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                img{
                    height:24px;
                }
            }
        }
        .div-none{
            flex:2
        }
    }
</style>
