<template>
    <div :style="{backgroundColor:'#f6f7fc'}">
        <div id="title" :style="{height:height,fontSize:fontSize,width:imgWidth+'px'}">
            <div class="title" :style="{fontSize:ifphone?'2rem':'',paddingTop:ifphone?'2.5rem':''}">{{title}}</div>
            <div class="text" :style="{fontSize:ifphone?'1.2rem':'',paddingTop:ifphone?'0.4rem':'',paddingBottom:ifphone?'0.3rem':'20px'}">{{text.length*(ifphone?16:23)>imgWidth?text.substring(0,(imgWidth)/(ifphone?16:23)-2)+'...':text}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        height:String,
        fontSize:String,
        title:String,
        text:String,
        screenWidth:Number,
        ifphone:Boolean
    },
    data(){
        return{
        }
    },
    computed:{
          imgWidth(){
            return (this.screenWidth-120)<800?this.screenWidth-120:800;
        },
    }
}
</script>
<style lang="scss" scoped>
#title{
    margin: 0 auto;
}

.title{
    padding-top: 70px;
    width: 245px;
	height: 30px;
    line-height: 30px;
	font-size: 30px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: 800;
    font-stretch: normal;
    letter-spacing: 0.0625rem;
    color: #333333;
}
.text{
    padding-top: 19px;
	font-family: MicrosoftYaHei;
	font-size: 22px;
    line-height: 22px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #999999;
}
</style>