<template>
    <div id="blank" :style="{height:height,backgroundColor:color}">

    </div>
</template>
<script>
export default {
    props:{
        height:String,
        color:String
    }
    
}
</script>
<style scoped>
#blank{
    background-color: #fff;
}
</style>