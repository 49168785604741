import {arrayToState, flatActions, flatGetters, flatMutations,resetGenerator } from '../../common/utils/helper.js'
const names = ['appName','companyName','icpNo','gongAnNo','address','tel']

const state = arrayToState(names)
const getters = {...flatGetters("", names)}
const mutations = {...flatMutations(names)}

const actions = {
    ...flatActions(names),
    reload({commit}){
        return new Promise(((resolve, reject) => {
            commit('appName','老照片修复精灵')
            commit('companyName','苏州指薪趣品服务外包有限公司')
            commit('icpNo','苏ICP备20031329号-3')
            commit('gongAnNo','苏公网安备32050602011932号')
            commit('address','苏州市吴中区木渎镇汇润国际商业广场1幢2108室')
            commit('tel','0512-65685970')
            resolve()
        }))
    }
}
export { names }
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

