import Vue from 'vue';
import VueRouter from 'vue-router';
import FirstPage from '@/pages/FirstPage'
import Privacy from '@/components/pp/privacy'
import Protocol from '@/components/pp/protocol'
import VueWechatTitle from 'vue-wechat-title'


Vue.use(VueRouter);
Vue.use(VueWechatTitle);
Vue.directive("title",{
    inserted(el){
        document.title = el.getAttribute("title")
    },
    bind(el){
        document.title = el.getAttribute("title")
    },
    update(el){
        document.title = el.getAttribute("title")
    }

})
const router = new VueRouter({
    routes: [
        {   
            path: '/',
            redirect: '/index' 
        },
        {
            path:'/index',
            component: FirstPage,
            // meta:{
            //     // title: '老照片修复精灵'
            //     title: ""
            // }
        },
        {
            path:'/Protocol',
            component: Protocol
        },
        {
            path:'/Privacy',
            component: Privacy
        }   
    ]
});
// router.beforeEach((to,from,next) =>{
//     // 路由发生变化修改页面title
//    if (to.meta.title) {
//      document.title = to.meta.title;
//    }
// });


export default router