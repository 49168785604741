<template>
    <div id="about"  :style="{marginBottom:ifphone?0+'px':'',height:ifphone?23.5+'rem':460+'px'}">
        <template v-if="!ifphone">
            <template>
                 <div v-if="screenWidth>1000"  class="about-context">
                    <div class="div-img" >
                        <div :style="{backgroundImage:'url('+img+')',backgroundRepeat: 'no-repeat',fontSize:32+'px'}">
                            {{appName}}
                        </div>
                    </div>
                    <div class="div-text" :style="{marginLeft:'50px'}">
                        <div class="div-text-divinSide">
                            <div class="div-text-one" :style="{fontSize:30+'px',lineHeight:34+'px'}" >
                                关于我们
                            </div>
                            <div class="div-text-two" :style="{width:56+'px',height:11+'px',marginTop:16+'px'}">
                                
                            </div>
                            <div class="div-text-three" :style="{fontSize:26+'px',marginTop:16+'px'}" >
                                {{appName}}是一款专业修复残破模糊老照片的工具。
                            </div>      
                            <div class="div-text-four" :style="{fontSize:22+'px',marginTop:30+'px',lineHeight:'36px'}">
                                <ul>
                                    <li :style="{lineHeight:32+'px',marginTop:'14px'}">
                                        使用人工智能技术,可以将模糊照片、第清晰度照片修复、还原。
                                    </li>
                                    <li :style="{marginTop:'14px'}">
                                        精准给黑白照片上色,还原符合场景的颜色。
                                    </li>
                                    <li :style="{lineHeight:32+'px',marginTop:'14px'}">
                                        还提供部分照片优化功能,修复过度曝光、夜间拍色的照片、雾天拍摄的照片、照片无损放大一倍。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="screenWidth<1000">
                <div  class="div-one" >
                <div class="div-one-img-div" :style="{backgroundImage:'url('+imgPhone+')',backgroundRepeat: 'no-repeat',height:100+'px'}">
                    {{appName}}
                </div>
                <div :style="{width:'96%'}"> 
                    <div class="div-text-one" :style="{textAlign:'center',marginTop:20+'px',fontSize:30+'px'}">
                        关于我们
                    </div>
                    <div class="div-text-two" :style="{margin:'20px auto 10px auto',width:80+'px',height:'8px',paddingLeft:32+'px',backgroundColor:'#39434f'}">
                        <div :style="{width:48+'px',height:'100%',backgroundColor:'#fff',float:'right'}">

                        </div>
                    </div>
                    <div class="div-text-three" :style="{marginLeft:0,fontSize:ifphone?1+'rem':'',fontSize:26+'px'}">
                        <ul>
                            <li :style="{listStyleType:'none',fontSize:26+'px'}" >
                                {{appName}}是一款专业修复残破模糊老照片的工具。
                            </li>
                        </ul>
                    </div>
                    <div class="div-text-four" :style="{lineHeight:30+'px',fontSize:ifphone?0.95+'rem':22+'px'}">
                        <ul>
                            <li>
                                使用人工智能技术,可以将模糊照片、第清晰度照片修复、还原。
                            </li>
                            <li>
                                精准给黑白照片上色,还原符合场景的颜色。
                            </li>
                            <li>
                                还提供部分照片优化功能,修复过度曝光、夜间拍色的照片、雾天拍摄的照片、照片无损放大一倍。
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </template>
        </template>
        <template v-if="ifphone">
            <div  class="div-one" >
             <div class="div-one-img-div" :style="{backgroundImage:'url('+imgPhone+')',backgroundRepeat: 'no-repeat',height:80+'px'}">
                    {{appName}}
            </div>
            <div :style="{width:'96%'}"> 
                <div class="div-text-one" :style="{textAlign:'center',marginTop:10+'px'}">
                    关于我们
                </div>
                <div class="div-text-two" :style="{margin:'10px auto 10px auto',width:4+'rem',paddingLeft:2+'rem',backgroundColor:'#39434f'}">
                    <div :style="{width:3+'rem',height:'100%',backgroundColor:'#fff',float:'right'}">

                    </div>
                </div>
                <div class="div-text-three" :style="{marginLeft:0,fontSize:ifphone?1+'rem':''}">
                    <ul>
                        <li :style="{listStyleType:'none'}" >
                            {{appName}}是一款专业修复残破模糊老照片的工具。
                        </li>
                    </ul>
                </div>
                <div class="div-text-four" :style="{lineHeight:1.2+'rem',fontSize:ifphone?0.95+'rem':''}">
                    <ul>
                        <li>
                            使用人工智能技术,可以将模糊照片、第清晰度照片修复、还原。
                        </li>
                        <li :style="{marginTop:'7px'}">
                            精准给黑白照片上色,还原符合场景的颜色。
                        </li>
                        <li style="marginTop:7px">
                            还提供部分照片优化功能,修复过度曝光、夜间拍色的照片、雾天拍摄的照片、照片无损放大一倍。
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </template>
    </div>
</template>
<script>
import valueGetters from "../../common/mixins/valueGetters.js";
export default {
    props:{
        ifphone:Boolean,
        screenWidth:Number,
    },
  mixins:[valueGetters],
    mounted(){
   
  },
  data(){
      return{
          img:require('@/static/about/aboutus.png'),
          imgPhone:require('@/static/about/about-phone.png'),
      }
  }
}
</script>
<style lang="scss" scoped>
    .div-text-divinSide{
    }
    .div-one-img-div1{
        text-align: center;
        background-position: center;
        background-size: 100% contain;
    }
    .div-one-img-div{
        text-align: center;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
    }
    ul{
        margin: 0;
        padding-left: 24px;
    }
    .div-one{
        height: 429px;
        width: 100%;
    }
    #about{
        padding: 1px;
        height: 35rem;
        width: 100%;
        .about-context{
            height: 100%;
            width: 100%;
            display: flex;
        }
    }
    .div-img{
        width: 49%;
        height: 460px;
        color: #fff;
        font-size: 3rem;
        div{
            margin-top: 50px;
            float: right;
            display: flex;
            align-items: center;
            justify-content: center;
	        height: 360px;
            width: 480px;
            background-position: center;
            background-size: contain 100%;
        }
    }
    .div-text{
        margin-left: 4rem;
        display: flex;
        align-items: center;
        text-align: left;
        height: 460px;
        width: 45rem;
    }   
    .div-text-one{
        line-height: 2.13rem;
        font-family: MicrosoftYaHei-Bold;
        font-size: 1.875rem;
        font-weight: 800;
        font-stretch: normal;
        letter-spacing: 0.0625rem;
        color: #333333;
    }
    .div-text-two{
        width: 4.75rem;
        height: 0.5rem;
        margin-top: 1rem;
        background-color: #39434F;
    }
    .div-text-three{
        margin-top: 1rem;
        width: 100%;
        font-family: MicrosoftYaHei;
        font-size: 1.625rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
        color: #333333;
    }
    .div-text-four{
        margin-top: 1.875rem;
        width: 100%;
        font-family: MicrosoftYaHei;
        font-size: 1.375rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.25rem;
        letter-spacing: 1px;
        color: #999999;
        li{
            margin-top: 0.9rem;
        }
    }
    
</style>