<template>
    <div id="concat" :style="{height:ifphone?240+'px':'30rem'}">
       <div class="concat-one" :style="{backgroundImage:'url('+require('@/static/about/contant-left.jpg')+')'}"></div>
       <div>
            <div class="bg" :style="{height:ifphone?240+'px':'100%',backgroundImage:'url('+require('@/static/about/contactUs.jpg')+')'}">
        </div>
         <div class="bg1" :style="{height:ifphone?240+'px':'25rem',marginTop:ifphone?'':'2.5rem'}">
            <div class="bg1-div1" :style="{width:ifphone?screenWidth+'px':'70rem',height:ifphone?100+'%':''}">
                <div class="bg1-div1-top" :style="{height:ifphone?50+'px':'',marginTop:ifphone?20+'px':''}">
                    <div class="top-text" :style="{fontSize:ifphone?16+'px':''}">
                        <div></div>
                        <div><img class="top-text-img" :src="leftimg" alt=""></div>
                        <div :style="{width:ifphone?'':'26rem'}"><span :style="{fontSize:ifphone?1.2+'rem':'1.625rem'}">即刻联系我们，开启老照片修复吧</span></div>
                        <div><img class="top-text-img" :src="rightimg" alt=""></div>
                        <div></div>
                    </div>
                    <div class="top-text2" :style="{fontSize:ifphone?1.1+'rem':'1.25rem',marginTop:ifphone?5+'px':'1.81rem'}"><span>我们会竭诚为您服务！</span></div>
                </div>
                <div class="bg1-div1-middle" :style="{width:ifphone?150+'px':'24.125rem',height:ifphone?58+'px':'7.19rem',marginTop:ifphone?'':'2.45rem'}" >
                    <div class="middle-text1" :style="{fontSize:ifphone?1+'rem':'1.625rem',marginTop:ifphone?1+'rem':'1.2rem'}"><span>联系方式</span></div>
                    <div class="middle-text2" :style="{fontSize:ifphone?1+'rem':'1.625rem',marginTop:ifphone?0.4+'rem':'1.5rem'}">
                        <div class="middle-text2-one" :style="{width:'2.5rem',height:'2.5rem',fontSize:ifphone?1+'rem':'1.625rem'}"><img  src="@/static/about/tel.svg"/></div>
                        <div class="middle-text2-Two" :style="{height:'2.5rem',lineHeight:'2.5rem',fontSize:ifphone?1+'rem':'1.5rem'}"><span>{{ tel }}</span></div>
                    </div>
                </div>
                <div class="bg1-div1-bottom" :style="{width:ifphone?320+'px':'40rem',marginTop:ifphone?'':2+'rem'}">
                    <div class="bg1-div1-bottom-one"  :style="{fontSize:ifphone?1.5+'rem':'',marginTop:'0.5rem'}" >更多关注</div>
                    <template v-for="item,index in iconlist">
                        <div @click="trun(item.download)" class="icon-text" :style="{fontSize:ifphone?12+'px':''}" style="flex: 1;"  v-bind:key="index">
                            <div class="icon-text-img"><img :style="{height:3+'rem'}" :src="item.url"/></div>
                            <div class="icon-text-icoName">{{item.icoName}}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
       </div>
       <div class="concat-two" :style="{backgroundImage:'url('+require('@/static/about/contant-right.jpg')+')'}"></div>
    </div>
</template>
<script>
import valueGetters from "../../common/mixins/valueGetters.js";

export default {
  mixins:[valueGetters],
    props:{
        ifphone:false,
    },
    data(){
        return{
            screenWidth:0,
            leftimg: require("@/static/about/left.png"),
            rightimg: require("@/static/about/right.png"),
            iconlist:[
                {
                    url:require("@/static/about/weixin.png"),
                    icoName:'微信公众号',
                    download:'https://mp.weixin.qq.com/s/e4zM0uLa4Jcc0Ci--j_W9g'
                },
                {
                    url:require("@/static/about/douyin.png"),
                    icoName:'抖音',
                    download:'https://www.douyin.com/user/MS4wLjABAAAA2ABTSn-3qAMPPlFp3PNWxdZUQOPjjFr1otVX3E_H1BffISLu4Vvt7xFht2JDf4V6?enter_from=recommend&enter_method=top_bar'

                },
                {
                    url:require("@/static/about/kuaishou.png"),
                    icoName:'快手',
                    download:'https://www.kuaishou.com/profile/3xtsc68aewdm7sk'
                },
                {
                    url:require("@/static/about/weibo.png"),
                    icoName:'微博',
                    download:'https://weibo.com/u/7723417819'
                },
                {
                    url:require("@/static/about/redbook.png"),
                    icoName:'小红书',
                    download:'https://www.xiaohongshu.com/user/profile/6030e3ea00000000010022d1?xhsshare=CopyLink&appuid=6030e3ea00000000010022d1&apptime=1637142911'

                }
            ]
        }
    },
    methods:{
        trun(url){
            if(url!=''){
                var link = document.createElement('a');
                link.target='_blank';
                link.href=url;
                document.body.appendChild(link);
                link.click();
                document.body.removeAttribute(link);
            }
          
        }
    },
    mounted(){
        var screenWidth = document.body.clientWidth;
        console.log(screenWidth);
        if(screenWidth<750){
            // this.ifphone=true;
            this.screenWidth=screenWidth;
        }
    }
    
}
</script>
<style lang="scss" scoped>
.icon-text-icoName{
    line-height: 0.875rem;
    flex: 1;
    font-size: 0.8rem;
    font-weight: normal;
	font-stretch: normal;
    font-family: MicrosoftYaHei;
    line-height: 0.875rem;
}
.icon-text-img{
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-text{
    height: 100%;
    color: #fffefe;
    display: flex;
    flex-direction: column;
}
.bg1-div1-bottom-one{
    // margin-top: 26px;
    margin-left: 1.56rem;
    margin-right: 1.56rem;
    width: 3.375rem;
    line-height: 2.25rem;
	font-family: MicrosoftYaHei;
	font-size: 1.625rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0.0625rem;
	color: #fffefe;
    display: flex;
    justify-content: center;
    align-items: center;
}
.middle-text2-one{

    width: 2rem;
    height: 2rem;
    margin-left: -0.2rem;
    margin-right: 0.3rem;
    img{
        height:80%;
        width: 80%;
        bottom: 0;
        margin-top: 10%;
    }
}
.middle-text2-Two{
}
.middle-text1{
    margin-top: 0.5rem;
    line-height: 1rem;
	height: 1rem;;
	font-family: MicrosoftYaHei;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #ffffff;
}
.middle-text2{
    margin-top: 0.5rem;
    display: flex;
	height: 1rem;
    line-height: 1rem;
	font-family: MicrosoftYaHei;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #ffffff;
}
.top-text2{
    margin-top: 1rem;
    line-height: 1rem;
	height: 1rem;
	font-family: MicrosoftYaHeiLight;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #ffffff;
}
.top-text{
    line-height: 1.2rem;
	font-family: MicrosoftYaHei;
	font-size: 1.2rem;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0.0625px;
	color: #ffffff;
    height: 1.2rem;
    width: 100%;
    display: flex;
}
.top-text :nth-child(1){
    flex: 2;
}
.top-text :nth-child(5){
    flex: 2;
}
.top-text :nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.top-text :nth-child(3){
    width: 20rem;
}
.top-text :nth-child(4){
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.bg1-div1-top{
    margin:0 auto;
    height: 4.275rem;
    width: 100%;
}
.bg1-div1-middle{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:0 auto;
    height: 4rem;
    width: 13rem;
	border: solid 1px rgba(255,255,255,0.5);
}
.bg1-div1-bottom{
    display: flex;
    margin:0 auto;
    margin-top: 1rem;
    height: 6rem;
    width: 63%;
}
.bg1-div1{
    width: 49rem;
}
#concat{
    position:relative;
    height: 17.5625rem;
    width: 100%;
    text-align: center;
    overflow: hidden;
}
.concat-one{
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    background-size: auto 100%;
}
.concat-two{
    position: absolute;
    height: 100%;
    width: 50%;
    right: 0;
    background-size: auto 100%;
}
.bg{
    z-index: 1;
    position: absolute;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
}
.bg1{
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
    position: absolute;
    width: 100%;
    margin-top: 1rem;
}
.top-text-img{
    width: 100%;
}
</style>