<template>
  <div id="app" >
    <Navbar :style="{backgroundColor:'#f6f7fc'}" :screenWidth="screenWidth" :ifphone="flag"></Navbar>
    <router-view v-wechat-title="$route.meta.title"></router-view>
    <Concat :ifphone="flag"></Concat>
    <!-- <Share :img="require('@/static/function/overDarkRepair/overDarkRepairBefore.png')" shape="true"></Share> -->
    <Foot :ifphone="flag"></Foot>
  </div>
</template>
<script type="text/javascript">
import Navbar from '@/components/Navbar'
import Concat from '@/components/about/concat'
import Foot from '@/components/foot/Foot'
import Share from '@/components/share/share'
export default {
  name: 'App',
  components: {
    Navbar,
    Concat,
    Foot,
    Share
  },
  mounted () {
    this.fontSize;
    const that = this
    window.onresize = () => {
        return (() => {
            that.per= that.screenWidth/that.offsetWidth*100;
            if(that.flag){
                document.getElementsByTagName("html")[0].style.fontSize = (this.flag?70:100) + '%';
            }else{
              document.getElementsByTagName("html")[0].style.fontSize = that.per + '%';
            }
            that.screenWidth = document.body.clientWidth;
            console.log("that.per"+that.per)
            
        })()
    }
    that.$store.dispatch('site/reload')
  },
  data(){
    return {
      per:100,
      offsetWidth: document.body.offsetWidth ,
      screenWidth: document.body.clientWidth,
      flag:false
    }
  },
  created(){
      this.isMobile();
  },
  methods: {
      avatarChanged(item,index){
				this.currentAvatar = index;
			},
      isMobile() {
          this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)!=null;
      }
  
  },
  computed:{
			comparatorWidth(){
				return 690;
      },
      ifphone(){
        return this.screenWidth<900?true:false;
      },
      fontSize(){
        document.getElementsByTagName("html")[0].style.fontSize = (this.flag?70:100) + '%';
        return this.ifphone?70:100;
      }
  }
}
</script>
<style>

</style>